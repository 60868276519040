package com.macrofocus.nastac.components

import com.macrofocus.common.properties.MutableProperty
import com.macrofocus.nastac.ui.react.toState
import js.core.jso
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mui.icons.material.PlayCircleOutlineOutlined
import mui.icons.material.StopCircleOutlined
import mui.material.*
import react.FC
import react.Props
import react.dom.aria.ariaLabel
import react.dom.events.SyntheticEvent
import react.useState
import web.cssom.px
import web.events.Event

external interface TimeSliderProps : Props {
    var shiftValue: MutableProperty<Int>
    var localShiftValue: MutableProperty<Int>
}

val TimeSlider = FC<TimeSliderProps> { props ->
    val (getShiftValue, setShiftValue) = props.shiftValue.toState()
    val (getLocalShiftValue, setLocalShiftValue) = props.localShiftValue.toState()

    val (getJob, setJob) = useState<Job?>(null)

    IconButton {
//                        classes(classes.menuButton)
        color = IconButtonColor.inherit
//                    edge = IconButtonEdge.start
        onClick = {
            val job = getJob
            if(job != null) {
                job.cancel()
                setJob(null)
            } else {
                val ms = 500L
                println("Starting animation with 5 minutes = $ms ms")
                setJob(GlobalScope.launch {
                    var current = getLocalShiftValue
                    while (true) {
                        delay(ms)
                        current = if(current < 1815) current + 5 else 1490

//                                println("Next date: " + next.toRawString())
//                                masterDateTime.value = next.toDateTime()
                        println("Shifting to $current")
                        setLocalShiftValue(current)
                        setShiftValue(current)
                    }
                })
            }
        }

        if(getJob != null) StopCircleOutlined {} else PlayCircleOutlineOutlined {}
//        Icon { +if(getJob != null) "stop_circle_outline_icon" else "play_circle_outline_icon" }
    }

    Slider {
        sx = jso {
            width = 210.px
            marginRight = 10.px
        }

        disabled = getJob != null
//        ariaLabel = "Shift"
        value = getLocalShiftValue
//                        getAriaValueText={valuetext}
        valueLabelDisplay = "auto"
        step = 5
        marks
        min = 1490
        max = 2017
        size = Size.small
        valueLabelDisplay = "on"
        onChange = { event: Event, value: dynamic, activeThumb: Number ->
            println("onChange: $value")
            if (value is Number) {
                setLocalShiftValue(value.toInt())
            }
        }
        onChangeCommitted = { event: SyntheticEvent<*, *>, value: dynamic ->
            println("onChangeCommitted: $value")
            if (value is Number) {
                setLocalShiftValue(value.toInt())
                setShiftValue(value.toInt())
            }
        }
    }
}