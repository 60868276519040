package com.macrofocus.nastac

enum class ViewStyle {
    TILLY {
        override val classname: String = "tilly"

        override fun apply(dataLoader: DataLoader) {
            dataLoader.borders.value = true
            dataLoader.ethnicity.value = false

            dataLoader.population.value = false
            dataLoader.depthTest.value = false
        }

        override fun toString(): String {
            return "War in Early Modern Europe"
        }
    },

    SIZE {
        override val classname: String = "size"

        override fun apply(dataLoader: DataLoader) {
            dataLoader.borders.value = false
            dataLoader.ethnicity.value = true
            dataLoader.shiftValue.value = 1790

            dataLoader.population.value = false
            dataLoader.depthTest.value = false
        }

        override fun toString(): String {
            return "States and nations"
        }
    },

    POPULATION {
        override val classname: String = "population"

        override fun apply(dataLoader: DataLoader) {
            dataLoader.population.value = true
            dataLoader.depthTest.value = true
        }

        override fun toString(): String {
            return "Population"
        }
    },

    RSHAPES {
        override val classname: String = "rshapes"

        override fun apply(dataLoader: DataLoader) {
//            dataLoader.borders.value = true
//            dataLoader.ethnicity.value = false
            dataLoader.railway.value = true

            dataLoader.population.value = false
            dataLoader.depthTest.value = false
        }

        override fun toString(): String {
            return "Railways"
        }
    };

    abstract val classname : String
    abstract fun apply(configuration: DataLoader)
}