package com.macrofocus.eflux.ui.react

import react.useEffect
import react.useRef
import web.timers.clearInterval
import web.timers.setInterval

fun useInterval(callback: () -> Unit, delay: Int?) {
    val savedCallback = useRef<() -> Unit>();

    // Remember the latest function.
    useEffect(callback) {
        savedCallback.current = callback;
    }

    // Set up the interval.
    useEffect(delay) {
        if(delay != null) {
            val id = setInterval({ savedCallback.current?.let { it() } }, delay);

            cleanup {
                clearInterval(id);
            }
        }
    }
}