package com.macrofocus.eflux.animation

import com.macrofocus.common.properties.MutableProperty

abstract class CompositeAnimation : Animation {
    abstract fun addAnimation(animation: Animation)

    fun property(property : MutableProperty<Double>, endValue: Double, duration: Long, easing: Easing = Easing.LINEAR, init: PropertyAnimation.() -> Unit = {}) : PropertyAnimation{
        val animation = PropertyAnimation(property, endValue, duration, easing)
        animation.init()
        addAnimation(animation)
        return animation
    }
}