package org.molap.geojson

import com.macrofocus.common.json.*
import org.locationtech.jts.geom.*
import org.molap.dataframe.AbstractDataFrame
import org.molap.index.DefaultUniqueIndex
import org.molap.index.IntegerRangeUniqueIndex
import org.molap.index.UniqueIndex
import kotlin.reflect.KClass

actual class GeoJSONDataFrame constructor(val root: JsonObject) : AbstractDataFrame<Int, String?, Any?>() {
    private val geometryFactory: GeometryFactory = GeometryFactory()
    private val labels: Array<String?>
    private val classes: Array<KClass<out Any>>
    private val array: JsonArray?
    private var hasGeo = false

    actual constructor(json: String) : this(JsonFactory.parse<JsonObject>(json)) {
    }

    init {
        array = root.getArray("features")
        val keys = LinkedHashSet<String>()
        if(array != null) {
            for (i in 0 until array.length()) {
                val feature: JsonObject? = array.getObject(i)
                val properties: JsonObject? = feature!!.getObject("properties")
                for (key in properties!!.keys()) {
                    keys.add(key)
                }
                if (!hasGeo) {
                    hasGeo = feature.hasKey("geometry")
                }
            }
        }
        if (hasGeo) {
            keys.add("geometry")
        }
        val labels : Array<String?> = arrayOfNulls(keys.size)
        var index = 0
        for (key in keys) {
            labels[index] = key
            index++
        }
        this.labels = labels
        val classes : Array<KClass<out Any>?> = arrayOfNulls(keys.size)
        for (i in classes.indices) {
            classes[i] = Any::class
        }
        this.classes = classes.requireNoNulls()
        if (hasGeo) {
            classes[classes.size - 1] = Geometry::class
        }
    }

    override fun getRowClass(row: Int): KClass<*> {
        return Any::class
    }

    override fun getColumnClass(column: String?): KClass<out Any> {
        return classes[columnIndex.getAddress(column)]
    }

    override fun getValueAt(row: Int, column: String?): Any? {
        return getValueAt(getRowAddress(row), getColumnAddress(column))
    }

    override val rowIndex: UniqueIndex<Int> by lazy { IntegerRangeUniqueIndex(0, array!!.length() - 1) }
    override val columnIndex: UniqueIndex<String?> by lazy {
        val names = ArrayList<String?>(labels.size)
        for (c in labels.indices) {
            names.add(labels[c])
        }
        DefaultUniqueIndex<String?>(names)
    }

    actual fun getJsonGeometry(rowIndex: Int) : JsonObject? {
        val feature: JsonObject = array!!.get(rowIndex)
        val jsonObject: JsonObject? = feature.getObject("geometry")
        return jsonObject
    }

    actual fun getGeometry(rowIndex: Int) : Geometry? {
        val jsonGeometry: JsonObject? = getJsonGeometry(rowIndex)
        if (jsonGeometry != null) {
            var geometry: Geometry? = null
            try {
                geometry = parseGeometry(jsonGeometry)
            } catch (e: IllegalArgumentException) {
                e.printStackTrace()
            }
            return geometry
        } else {
            return null
        }
    }

    fun getValueAt(rowIndex: Int, columnIndex: Int): Any? {
        return try {
            if (hasGeo && columnIndex == labels.size - 1) {
                getGeometry(rowIndex)
            } else {
                val JSonObject: JsonObject? = array?.getObject(rowIndex)?.getObject("properties")
                if (JSonObject!!.hasKey(labels[columnIndex]!!)) {
                    val value: JsonValue = JSonObject.get(labels[columnIndex]!!)
                    if (value.type === JsonType.NULL) {
                        null
                    } else if (value.type == JsonType.ARRAY) {
                        val array: JsonArray = value.asJsonArray()!!
                        val a = arrayOfNulls<Any>(array.length())
                        for (i in 0 until array.length()) {
                            a[i] = array.get(i)
                        }
                        a
                    } else {
                        value
                    }
                } else {
                    null
                }
            }
        } catch (e: JsonException) {
            e.printStackTrace()
            null
        }
    }

    /**
     * Parse all geometry types, including Point, LineString, Polygon, MultiPoing, MultiLineString, MultiPolygon
     *
     * @param obj
     *
     * @return
     *
     * @throws IllegalArgumentException
     */
    private fun parseGeometry(obj: JsonObject): Geometry {
        if (!obj.hasKey("type")) {
            throw UnsupportedOperationException("no type attribute")
        }
        val type: String? = obj.getString("type")
        if (type.equals("Point", ignoreCase = true)) {
            return parsePoint(obj)
        } else if (type.equals("LineString", ignoreCase = true)) {
            return parseLineString(obj)
        } else if (type.equals("Polygon", ignoreCase = true)) {
            return parsePolygon(obj)
        } else if (type.equals("MultiPoint", ignoreCase = true)) {
            return parseMultiPoint(obj)
        } else if (type.equals("MultiLineString", ignoreCase = true)) {
            return parseMultiLineString(obj)
        } else if (type.equals("MultiPolygon", ignoreCase = true)) {
            return parseMultiPolygon(obj)
        } else if (type.equals("GeometryCollection", ignoreCase = true)) {
            return parseGeometryCollection(obj)
        }
        throw IllegalArgumentException(type)
    }

    /**
     * Parse Point
     *
     * @param obj
     *
     * @return
     */
    private fun parsePoint(obj: JsonObject): Point {
        return parseCoordinatePoint(obj.getArray("coordinates")!!)
    }

    /**
     * Parse LineString
     *
     * @param obj
     *
     * @return
     */
    private fun parseLineString(obj: JsonObject): LineString {
        return parseCoordinateLineString(obj.getArray("coordinates")!!)
    }

    /**
     * Parse Polygon
     *
     * @param obj
     *
     * @return
     */
    private fun parsePolygon(obj: JsonObject): Polygon {
        return parseCoordinatePolygon(obj.getArray("coordinates")!!)
    }

    /**
     * Parse MultiPoint
     *
     * @param obj
     *
     * @return
     */
    private fun parseMultiPoint(obj: JsonObject): MultiPoint {
        return geometryFactory.createMultiPointFromCoords(parseCoordinates(obj.getArray("coordinates")!!))!!
    }

    /**
     * Parse MultiLineString
     *
     * @param obj
     *
     * @return
     */
    private fun parseMultiLineString(obj: JsonObject): MultiLineString {
        val c: JsonArray = obj.getArray("coordinates")!!
        val lines: Array<LineString?> = arrayOfNulls<LineString>(c.length())
        for (i in lines.indices) {
            lines[i] = parseCoordinateLineString(c.getArray(i)!!)
        }
        return geometryFactory.createMultiLineString(lines.requireNoNulls())!!
    }

    /**
     * Parse MultiPolygon
     *
     * @param obj
     *
     * @return
     */
    private fun parseMultiPolygon(obj: JsonObject): MultiPolygon {
        val _c: JsonArray = obj.getArray("coordinates")!!
        val polys: Array<Polygon?> = arrayOfNulls<Polygon>(_c.length())
        for (i in polys.indices) {
            polys[i] = parseCoordinatePolygon(_c.getArray(i)!!)
        }
        return geometryFactory.createMultiPolygon(polys.requireNoNulls())
    }

    /**
     * Parse Box
     *
     * @param obj
     *
     * @return
     */
    private fun parseBox(obj: JsonObject): Envelope {
        val _pts: Array<Coordinate> = parseCoordinates(obj.getArray("coordinates")!!)
        if (_pts.size != 2) {
            throw IndexOutOfBoundsException()
        }
        return Envelope(_pts[0], _pts[1])
    }

    /**
     * Parse GeometryCollection
     *
     * @param obj
     *
     * @return
     */
    private fun parseGeometryCollection(obj: JsonObject): GeometryCollection {
        if (!obj.getString("type").equals("GeometryCollection")) {
            throw UnsupportedOperationException("Not GeometryCollection type")
        }
        val _list: JsonArray? = if (obj.hasKey("members")) {
            obj.getArray("members")
        } else {
            obj.getArray("geometries")
        }
        val cols: Array<Geometry?> = arrayOfNulls<Geometry>(_list!!.length())
        for (i in cols.indices) {
            cols[i] = parseGeometry(_list.getObject(i)!!)
        }
        return geometryFactory.createGeometryCollection(cols.requireNoNulls())
    }

    private fun parseCoordinate(obj: JsonArray): Coordinate {
        if (obj.length() < 2) {
            throw IndexOutOfBoundsException("Not enough coordinates")
        }
        return Coordinate(obj.getNumber(0), obj.getNumber(1))
    }

    private fun parseCoordinates(obj: JsonArray): Array<Coordinate> {
        val cs: Array<Coordinate?> = arrayOfNulls<Coordinate>(obj.length())
        for (i in 0 until obj.length()) {
            cs[i] = parseCoordinate(obj.getArray(i)!!)
        }
        return cs.requireNoNulls()
    }

    private fun parseCoordinatePoint(obj: JsonArray): Point {
        return geometryFactory.createPoint(parseCoordinate(obj))
    }

    private fun parseCoordinateLineString(obj: JsonArray): LineString {
        return geometryFactory.createLineString(parseCoordinates(obj))
    }

    private fun parseCoordinateLineRing(obj: JsonArray): LinearRing {
        return geometryFactory.createLinearRing(parseCoordinates(obj))
    }

    private fun parseCoordinatePolygon(obj: JsonArray): Polygon {
        if (obj.length() < 0) {
            throw IndexOutOfBoundsException("Not enough line string")
        }
        val _out: LinearRing = parseCoordinateLineRing(obj.getArray(0)!!)
        val inners = Array(obj.length() - 1, {
            parseCoordinateLineRing(obj.getArray(it + 1)!!)
        })
        return geometryFactory.createPolygon(_out, inners)
    }
}
