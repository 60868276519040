package com.macrofocus.nastac

import web.cssom.px

object Sizes {
    object Header {
        val Height = 64.px
    }

    object Sidebar {
        val Width = 300.px
    }

    object Fabbar {
        val Width = 184.px
    }
}