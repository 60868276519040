package com.macrofocus.nastac.events

import com.macrofocus.common.properties.MutableProperty
import com.macrofocus.nastac.DataLoader
import i18next.invoke
import i18next.useTranslation
import mui.icons.material.BookmarksTwoTone
import mui.icons.material.ExpandMoreTwoTone
import mui.material.*
import react.*
import react.dom.aria.ariaLabel
import react.dom.events.MouseEvent
import web.html.HTMLButtonElement

external interface EventsButtonProps : PropsWithChildren {
    var configuration: DataLoader

    var mode: EventMode
    var events: Array<Event>
}

val EventsButton = FC<EventsButtonProps> { props ->
    val (t, _) = useTranslation()

    val (eventsMenu, setEventsMenu) = useState<HTMLButtonElement?>(null)

    fun handleIconClick(event: MouseEvent<*, *>) = setEventsMenu(event.currentTarget as? HTMLButtonElement)
    fun handleMenuClose() = setEventsMenu(null)

    val (selectedEvent, setSelectedEvent) = useState<Event?>(null)

    Tooltip {
        title = ReactNode(t("appBar.bookmarks"))

        Button {
            color = ButtonColor.secondary
            ariaLabel = t("appBar.bookmarks")

            onClick = ::handleIconClick

            BookmarksTwoTone {}
            ExpandMoreTwoTone {
                fontSize = SvgIconSize.small
            }
        }
    }

    Menu {
        id = "events-menu"
        eventsMenu?.let { anchorEl = { eventsMenu } }
        open = eventsMenu != null
        onClose = ::handleMenuClose

        for(event in props.events) {
            MenuItem {
                selected = selectedEvent == event
                onClick = { e ->
                    setSelectedEvent(event)
                    handleMenuClose()
                }

                +event.title
            }
        }
    }

    if(selectedEvent != null) {
        for(event in props.events) {
            EventDialog {
                this.configuration = props.configuration
                open = selectedEvent == event
                this.event = event
                mode = props.mode
                onClose = { _, _ -> setSelectedEvent(null) }
            }
        }
    }
}