package com.macrofocus.nastac.components

import ch.ethz.nastac.server.BuildKonfig
import com.macrofocus.nastac.Sizes
import com.macrofocus.nastac.theme.ThemeContext
import csstype.*
import mui.material.PaletteMode
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import react.useRequiredContext
import web.cssom.*

val SidebarContent = FC<Props> {
    val DEBUG = false

    var theme by useContext(ThemeContext)!!
    val nastac = useContext(NASTACContext)!!

    Box {
        sx {
            width = Sizes.Sidebar.Width
            display = Display.inlineFlex
            justifyContent = JustifyContent.center
        }

        Logo {
//                sx { width = Sizes.Sidebar.Width }

            version = BuildKonfig.nastacVersion
            logo = if (theme.palette.mode == PaletteMode.light) "/eth_logo_kurz_pos.svg" else "/eth_logo_kurz_neg.svg"
            height = 54.0
            marginLeft = -12.px
        }
    }

    Box {
        sx {
            display = Display.inlineFlex
            marginLeft = 10.px
        }

//        DateChooser {
//            timeTracker = diviso.configuration.timeTracker
//        }
    }

    Box {
        sx {
            width = 100.pct
            height = 185.px
        }

        ViewStyleLinks {
            this.viewStyle = nastac.dataLoader.viewStyle
        }
    }

    Box {
        sx {
            display = Display.inlineFlex
            marginLeft = 10.px
        }

        TimeSlider {
            this.shiftValue = nastac.dataLoader.shiftValue
            this.localShiftValue = nastac.dataLoader.localShiftValue
//            timeTracker = diviso.configuration.timeTracker
//            timeUnitChooser = false
//            valueLabelDisplay = "off"
//            width = 210.px
        }
    }
}