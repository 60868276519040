package com.macrofocus.nastac.events

import mui.material.CardHeader
import mui.system.sx
import react.FC
import react.PropsWithRef
import react.ReactNode
import react.ref
import web.cssom.None
import web.html.HTMLDivElement

external interface HeaderProps : PropsWithRef<HTMLDivElement> {
    var title: String?
}

val Header = FC<HeaderProps> { props ->
    CardHeader {
        sx {
            textTransform = None.none
        }

        title = ReactNode(props.title!!)
        this.ref = props.ref
    }
}