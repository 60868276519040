package com.macrofocus.nastac

import kotlin.js.Date

fun String.toRawDate() : Date {
    val year = substring(0, 4).toInt()
    val month = substring(5, 7).toInt()
    val day = substring(8, 10).toInt()
    val date = Date(year, month - 1, day, 0, 0, 0, 0)
    return date
}

fun Date.toRawString() : String {
    return getFullYear().toString().padStart(4, '0') +
            "-" +
            (getMonth() + 1).toString().padStart(2, '0') +
            "-" +
            getDate().toString().padStart(2, '0')
}

fun Date.equalOrAfter(date: Date) : Boolean {
    return getTime() >= date.getTime()
}

fun Date.equalOrBefore(date: Date) : Boolean {
    return getTime() <= date.getTime()
}