package com.macrofocus.nastac.ui

import com.macrofocus.eflux.animation.Easing
import com.macrofocus.eflux.animation.Playback
import com.macrofocus.eflux.animation.timeline
import com.macrofocus.nastac.DataLoader
import com.macrofocus.nastac.components.TimeSeries
import com.macrofocus.nastac.theme.ThemeContext
import com.macrofocus.nastac.toRawDate
import com.macrofocus.nastac.ui.react.toState
import external.mosaic.MosaicParent
import external.mosaic.MosaicWindow
import external.mosaic.MosaicWindowProps
import external.mosaic.mosaic
import js.core.jso
import mui.icons.material.ExpandMore
import mui.material.*
import mui.system.sx
import react.*
import react.dom.aria.ariaControls
import react.dom.html.ReactHTML.div
import web.cssom.pct
import web.dom.document

enum class Windows {
    Query,
    Geography,
    TimeSeries,
}

external interface EFluxProps : Props {
    var dataLoader: DataLoader
//    var divRef: RefObject<HTMLDivElement>
//    var svgRef: RefObject<SVGElement>
}

val EFluxApp = FC<EFluxProps> { props ->
    var theme by useContext(ThemeContext)!!
    val paletteType = if (theme.asDynamic().palette.mode == "dark") PaletteType.dark else PaletteType.light

//    val (dateString, _) = props.dataLoader.dateString.toState()
//    val (world, _) = props.dataLoader.world.toState()

//    val (dnd, _) = props.dataLoader.dnd.toState()

    val background: String = if (paletteType == PaletteType.dark) "#062333" else "#FFFFFF"
    val landmass: String = if (paletteType == PaletteType.dark) "#0B374F" else "#E2E2E2"

    val nodeStroke: String = if (paletteType == PaletteType.dark) "#888888" else "#666666"
    val nodeFill: String = if (paletteType == PaletteType.dark) "#0B374F" else "#FFFFFF"
    val textFill: String = if (paletteType == PaletteType.dark) "#D1D1D1" else "#141515"

    var (coverage, setCoverage) = props.dataLoader.coverage.toState()

//    if(world != null) {
//        try {
//            update(dateString, world, nodeStroke, nodeFill, paletteType)
//        } catch (e: Exception) {
//            console.log(e)
//        }
//    }

//    world?.let {
//        it.applyThemeColors(background, landmass, textFill, nodeStroke, nodeFill)
//    }

    val (currentNode, setCurrentNode) = useState<MosaicParent<Windows>?>(
        jso<MosaicParent<Windows>> {
//            direction = "row" // Horizontal split
//            splitPercentage = 20
//            first = Windows.Query
//            second = jso<MosaicParent<Windows>> {
//                direction = "column" // Vertical split
//                splitPercentage = 80
//                first = Windows.Geography
//                second = Windows.TimeSeries
//            }
            direction = "column" // Horizontal split
            splitPercentage = 60
            first = Windows.Geography
            second = Windows.TimeSeries
        }
    )

    val element = buildElement {
        mosaic<Windows> {
            var theme by useContext(ThemeContext)!!
            val paletteType = if (theme.asDynamic().palette.mode == "dark") PaletteType.dark else PaletteType.light

            attrs.value = currentNode
            attrs.onChange = { newNode -> setCurrentNode(newNode as MosaicParent<Windows>?) }
            attrs.className =
                if (paletteType == PaletteType.dark) "mosaic mosaic-blueprint-theme bp3-dark" else "mosaic mosaic-blueprint-theme bp3-light"
            attrs.zeroStateView = document.createElement("div")
            attrs.resize = js("{\"minimumPaneSizePercentage\": 1}")

            val (country, _) = props.dataLoader.countrySelection.toState()

            attrs.renderTile = { window, path ->
                // TODO: Ugh, are there more idiomatic ways to do any of this? Yes! buildElement {}

                createElement(
                    MosaicWindow,
                    jso<MosaicWindowProps<Windows>> {
                        this.draggable = true
                        this.title = if(window == Windows.TimeSeries && country != null) country else if(window == Windows.Geography) null else window.name
                        this.path = path
//                        this.renderToolbar = { props, isDraggable ->
//                            buildElement {
//                                div {
//                                    +(window.name)
//                                }
//                            }
//                        }
                    },

                    createElement(
                        div, jso {},
                        when (window) {
                            Windows.Query -> {
                                Stack.create() {
                                    Accordion {
                                        AccordionSummary {
                                            id = "panel1a-header"
                                            ariaControls = "panel1a-content"
                                            expandIcon = ExpandMore.create()

                                            Typography {
                                                +"Warfare did play a crucial role in the territorial expansion"
                                            }
                                        }
                                        AccordionDetails {
                                            Typography {
                                                Button {
                                                    +"Show me!"
                                                    onClick = {
                                                        val threeDPitch = props.dataLoader.threeDPitch
                                                        val threeDZoom = props.dataLoader.threeDZoom
                                                        val threeDLatitude = props.dataLoader.threeDLatitude
                                                        val threeDLongitude = props.dataLoader.threeDLongitude

                                                        val timeline = timeline {
                                                            val duration: Long = 20000
                                                            // Istanbul 41.013611, 28.955
                                                            parallel {
                                                                property(threeDZoom, 8.0, duration)
                                                                property(threeDLatitude, 41.013611, duration)
                                                                property(threeDLongitude, 28.955, duration)
                                                                property(threeDPitch, 60.0, duration, Easing.QUAD_IN)
                                                            }

                                                            parallel {
                                                                property(threeDZoom, 5.0, duration)
                                                                property(threeDLatitude, (41.013611 + 48.2) / 2, duration)
                                                                property(threeDLongitude, (28.955 + 16.366667) / 2, duration)
                                                            }

                                                            // Vienna 48.2, 16.366667
                                                            parallel {
                                                                property(threeDZoom, 8.0, duration)
                                                                property(threeDLatitude, 48.2, duration)
                                                                property(threeDLongitude, 16.366667, duration)
                                                            }

                                                            parallel {
                                                                property(threeDZoom, 5.0, duration)
                                                                property(threeDLatitude, (51.507222 + 48.2) / 2, duration)
                                                                property(threeDLongitude, (-0.1275 + 16.366667) / 2, duration)
                                                            }

                                                            // London 51.507222, -0.1275
                                                            parallel {
                                                                property(threeDZoom, 8.0, duration)
                                                                property(threeDLatitude, 51.507222, duration)
                                                                property(threeDLongitude, -0.1275, duration)
                                                            }

                                                            // Zurich
                                                            parallel {
                                                                property(threeDZoom, 8.0, duration)
                                                                property(threeDLatitude, 47.3769, duration)
                                                                property(threeDLongitude, 8.5417, duration)
                                                                property(threeDPitch, 60.0, duration, Easing.QUAD_IN)
                                                            }


                                                            parallel {
                                                                property(threeDZoom, 5.0, duration)
                                                                property(threeDLatitude, (47.3769 + 52.52) / 2, duration)
                                                                property(threeDLongitude, (8.5417 + 13.405) / 2, duration)
                                                            }

                                                            // Berlin 52.52, 13.405
                                                            parallel {
                                                                property(threeDZoom, 8.0, duration)
                                                                property(threeDLatitude, 52.52, duration)
                                                                property(threeDLongitude, 13.405, duration)
                                                            }
                                                        }

                                                        Playback(timeline).timeline.start()
                                                    }
                                                }

                                                +"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
                                            }

                                            Link {
                                                href = "https://icr.ethz.ch/publications/cshapes-2/"
                                                +"Mapping The International System, 1886-2017: The CShapes 2.0 Dataset Schvitz, Guy, Seraina Rüegger, Luc Girardin, Lars-Erik Cederman, Nils Weidmann, and Kristian Skrede Gleditsch. Journal of Conflict Resolution 66(1): 144–61."
                                            }
                                        }
                                    }

                                    Accordion {
                                        AccordionSummary {
                                            id = "panel2a-header"
                                            ariaControls = "panel2a-content"
                                            expandIcon = ExpandMore.create()

                                            Typography {
                                                +"After having increased for centuries, territorial state sizes started declining toward the end of the 19th century and have continued to do so until today"
                                            }
                                        }
                                        AccordionDetails {
                                            Typography {
                                                Button {
                                                    +"Show me!"
                                                    onClick = {
                                                        props.dataLoader.borders.value = false
                                                        props.dataLoader.ethnicity.value = true
                                                        props.dataLoader.depthTest.value = false
                                                        props.dataLoader.shiftValue.value = 1790
                                                    }
                                                }
                                                +"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
                                            }
                                        }
                                    }

                                    Accordion {
                                        AccordionSummary {
                                            id = "panel3a-header"
                                            ariaControls = "panel3a-content"
                                            expandIcon = ExpandMore.create()

                                            Typography {
                                                +"States’ internal ethnic fragmentation leads to reductions of states’ size"
                                            }
                                        }
                                        AccordionDetails {
                                            Typography {
                                                Button {
                                                    +"Show me!"
                                                    onClick = {
                                                        props.dataLoader.borders.value = true
                                                        props.dataLoader.ethnicity.value = false
                                                        props.dataLoader.depthTest.value = false
                                                    }
                                                }
                                                +"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
                                            }
                                        }
                                    }

                                    Accordion {
                                        AccordionSummary {
                                            id = "panel4a-header"
                                            ariaControls = "panel4a-content"
                                            expandIcon = ExpandMore.create()

                                            Typography {
                                                +"Cross-border presence of state-carrying ethnic groups makes state expansion more likely"
                                            }
                                        }
                                        AccordionDetails {
                                            Typography {
                                                Button {
                                                    +"Show me!"
                                                    onClick = {
                                                        props.dataLoader.population.value = true
                                                        props.dataLoader.depthTest.value = true
                                                    }
                                                }
                                                +"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
                                            }
                                            FormControlLabel {
                                                sx {
                                                    width = 100.pct
                                                }

                                                control = Slider.create() {
//                                                    ariaLabel = "Coverage"
                                                    value = coverage
                                                    onChange = { _, newValue, _ -> setCoverage(newValue) }
                                                    min = 0.0
                                                    max = 1.0
                                                    step = 0.1
                                                }
                                                label = ReactNode("Coverage")
                                            }
                                        }
                                    }

                                    Accordion {
                                        AccordionSummary {
                                            id = "panel5a-header"
                                            ariaControls = "panel5a-content"
                                            expandIcon = ExpandMore.create()

                                            Typography {
                                                +"Golden ages"
                                            }
                                        }
                                        AccordionDetails {
                                            Typography {
                                                +"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
                                            }
                                        }
                                    }

                                    Accordion {
                                        AccordionSummary {
                                            id = "panel6a-header"
                                            ariaControls = "panel6a-content"
                                            expandIcon = ExpandMore.create()

                                            Typography {
                                                +"Loss of power and/or unity increase the risk of civil and interstate conflict"
                                            }
                                        }
                                        AccordionDetails {
                                            Typography {
                                                +"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
                                            }
                                        }
                                    }
                                }

//                                ToggleButtonGroup.create {
//                                    orientation = Orientation.vertical
//                                    size = Size.small
//
//                                    ToggleButton {
//                                        selected = true
//                                        +"Warfare did play a crucial role in the territorial expansion"
//                                    }
//                                    ToggleButton {
//                                        +"After having increased for centuries, territorial state sizes started declining toward the end of the 19th century and have continued to do so until today"
//                                    }
//                                    ToggleButton {
//                                        +"States’ internal ethnic fragmentation leads to reductions of states’ size"
//                                    }
//                                    ToggleButton {
//                                        +"Cross-border presence of state-carrying ethnic groups makes state expansion more likely"
//                                    }
//                                    ToggleButton {
//                                        +"Golden ages"
//                                    }
//                                    ToggleButton {
//                                        +"Loss of power and/or unity increase the risk of civil and interstate conflict "
//                                    }
//                                }
                            }
                            Windows.Geography -> {
                                ThreeD.create {
                                    this.dataLoader = props.dataLoader
                                    this.show = true
                                }
                            }
                            Windows.TimeSeries -> {
//                                Typography.create {
//                                    +"Time series"
//                                }
                                TimeSeries.create() {
                                    this.dataLoader = props.dataLoader
                                }
                            }
                        }
                    )
                )
            }
        }
    }
    +element
}

fun update(
    dataString: String,
    nodeStroke: String,
    nodeFill: String,
    paletteType: PaletteType
) {
    println("Updating schema")

    val currentDate = dataString.toRawDate()
}

