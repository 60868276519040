package com.macrofocus.nastac.data

object HEG {
    val ethnicNames = setOf(
        "jewish",
        "english [org]",
        "tat [l6]",
        "swedish [org]",
        "slovak [org]",
        "albanian [l2]",
        "andi [org]",
        "french [org]",
        "adyghe [org]",
        "veps [org]",
        "georgian [org]",
        "galician [org]",
        "tatar [org]",
        "aghul [org]",
        "turkish [org]",
        "kumyk [org]",
        "osetin [org]",
        "khinalugh [org]",
        "bosnian [org]",
        "nogai [org]",
        "kazakh [org]",
        "berber [l2]",
        "ukrainian [org]",
        "belarusan [org]",
        "norwegian [org]",
        "lezgi [org]",
        "dido [org]",
        "portuguese [org]",
        "dutch [org]",
        "czech [org]",
        "bats [org]",
        "croatian [org]",
        "romanian [org]",
        "aromanian [org]",
        "kalmyk-oirat [org]",
        "kabardian [org]",
        "vod [org]",
        "kashubian [org]",
        "gaelic, irish [org]",
        "arabic [l5]",
        "welsh [org]",
        "rutul [org]",
        "karelian [org]",
        "estonian, standard [org]",
        "sorbian [l4]",
        "samoyed [l2]",
        "lak [org]",
        "danish [l7]",
        "liv [org]",
        "komi-permyak [org]",
        "svan [l2]",
        "rusyn [org]",
        "macedonian [org]",
        "dargwa [org]",
        "walloon [org]",
        "kurdish [l6]",
        "vlaams [org]",
        "spanish [org]",
        "serbian [org]",
        "mari [l2]",
        "romani [l5]",
        "latvian, standard [org]",
        "greek [l2]",
        "abaza [org]",
        "mingrelian [org]",
        "italian [org]",
        "ingush [org]",
        "azerbaijani [l4]",
        "occitan [org]",
        "polish [org]",
        "archi [org]",
        "mordvin [l2]",
        "chechen [org]",
        "german [l5]",
        "bulgarian [org]",
        "scots [org]",
        "khvarshi [org]",
        "slovene [org]",
        "komi-zyrian [org]",
        "abkhaz [org]",
        "avar [l4]",
        "kryts [org]",
        "breton [org]",
        "balkar [dial]",
        "catalan-valencian-balear [org]",
        "udmurt [org]",
        "turkmen [org]",
        "mansi [org]",
        "sardinian [l5]",
        "sami [l2]",
        "ladin [org]",
        "bezhta [org]",
        "armenian [l2]",
        "gagauz [org]",
        "frisian [l4]",
        "finnish [org]",
        "russian [org]",
        "romansch [org]",
        "basque [l1]",
        "lithuanian [org]",
        "friulian [org]",
        "karachay [dial]",
        "ingrian [org]",
        "gaelic, scottish [org]",
        "tsakhur [org]",
        "bashkort [org]",
        "hungarian [org]",
        "icelandic [org]",
        "corsican [org]",
        "tabassaran [org]",
        "moldovan [org]",
        "chuvash [org]"
    )
}