package com.macrofocus.nastac.components

import com.macrofocus.nastac.events.EventMode
import com.macrofocus.nastac.events.Events
import com.macrofocus.nastac.events.EventsButton
import mui.material.Fab
import mui.material.FabColor
import mui.material.FabVariant
import mui.material.Size
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import web.cssom.Position
import web.cssom.px
import web.cssom.rgb

val Fabs = FC<Props> {
    val nastac = useContext(NASTACContext)!!

    Box {
        sx {
            position = Position.absolute
            right = 6.px
            top = 16.px
        }

        Fab {
            sx {
                marginRight = 10.px
                backgroundColor = rgb(224, 224, 224, 0.5)
                hover {
                    backgroundColor = rgb(224, 224, 224, 0.75)
                }
            }
            variant = FabVariant.extended
            size = Size.small
            color = FabColor.inherit
            EventsButton {
                this.configuration = nastac.dataLoader
                mode = EventMode.MANUAL
                events = arrayOf(Events.PRUSSIA, Events.FRANCE, Events.HABSBURG)
            }
        }

        Fab {
            sx {
                marginRight = 10.px
                backgroundColor = rgb(224, 224, 224, 0.5)
                hover {
                    backgroundColor = rgb(224, 224, 224, 0.75)
                }
            }
            color = FabColor.inherit
            size = Size.small

            ThemeButton {}
        }
    }
}