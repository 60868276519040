package org.mkui.color.crossplatform

import kotlin.math.floor
import kotlin.math.max
import kotlin.math.min

expect class CPColor(r: UByte, g: UByte, b: UByte, a: UByte) : IColor {
    constructor(r: UByte, g: UByte, b: UByte)

    constructor(r: Int, g: Int, b: Int)

    constructor(r: Int, g: Int, b: Int, a: Int)

    constructor(rgba: Int)

    constructor(r: Float, g: Float, b: Float)

    constructor(r: Float, g: Float, b: Float, a: Float)

//    fun getNative() : NColor
}

fun CPColor.brighter(): CPColor {
    val FACTOR = 0.7
    var r = getRed().toInt()
    var g = getGreen().toInt()
    var b = getBlue().toInt()
    val alpha = getAlpha().toInt()

    /* From 2D group:
         * 1. black.brighter() should return grey
         * 2. applying brighter to blue will always return blue, brighter
         * 3. non pure color (non zero rgb) will eventually return white
         */

    /* From 2D group:
         * 1. black.brighter() should return grey
         * 2. applying brighter to blue will always return blue, brighter
         * 3. non pure color (non zero rgb) will eventually return white
         */
    val i = (1.0 / (1.0 - FACTOR)).toInt()
    if (r == 0 && g == 0 && b == 0) {
        return CPColor(i, i, i, alpha)
    }
    if (r > 0 && r < i) r = i
    if (g > 0 && g < i) g = i
    if (b > 0 && b < i) b = i

    return CPColor(
        min((r / FACTOR).toInt(), 255),
        min((g / FACTOR).toInt(), 255),
        min((b / FACTOR).toInt(), 255),
        alpha
    )

}

fun CPColor.alpha(alpha: Float): CPColor {
    return CPColor(this.getRed(), this.getGreen(), this.getBlue(), (255 * alpha).toUInt().toUByte())
}

fun CPColor.darker(): CPColor {
    val FACTOR = 0.7
    return CPColor(
        max((this.getRed().toDouble() * FACTOR).toInt(), 0),
        max((this.getGreen().toDouble() * FACTOR).toInt(), 0),
        max((this.getBlue().toDouble() * FACTOR).toInt(), 0),
        this.getAlpha().toInt()
    )
}

fun CPColor.brightenAndSaturate(brightness: Float, saturation: Float): CPColor {
    val hsb: FloatArray = RGBtoHSB(getRed().toInt(), getGreen().toInt(), getBlue().toInt(), null)

    if (brightness != 0f) {
        hsb[2] = max(0.0f, min(1.0f, hsb[2] + brightness))
    }

    if (saturation != 0f) {
        hsb[1] = max(0.0f, min(1.0f, hsb[1] + saturation))
    }

//            hsb[2] = Math.max(0.0f, Math.min(1.0f, hsb[2] * (1.0f + (brightness / 100f))));


//            hsb[2] = Math.max(0.0f, Math.min(1.0f, hsb[2] * (1.0f + (brightness / 100f))));
    return CPColor(HSBtoRGB(hsb[0], hsb[1], hsb[2]))
}

fun RGBtoHSB(r: Int, g: Int, b: Int, hsbvals: FloatArray?): FloatArray {
    var hsbvals = hsbvals
    var hue: Float
    val saturation: Float
    val brightness: Float
    if (hsbvals == null) {
        hsbvals = FloatArray(3)
    }
    var cmax = if (r > g) r else g
    if (b > cmax) cmax = b
    var cmin = if (r < g) r else g
    if (b < cmin) cmin = b
    brightness = cmax.toFloat() / 255.0f
    saturation = if (cmax != 0) (cmax - cmin).toFloat() / cmax.toFloat() else 0f
    if (saturation == 0f) hue = 0f else {
        val redc = (cmax - r).toFloat() / (cmax - cmin).toFloat()
        val greenc = (cmax - g).toFloat() / (cmax - cmin).toFloat()
        val bluec = (cmax - b).toFloat() / (cmax - cmin).toFloat()
        hue = if (r == cmax) bluec - greenc else if (g == cmax) 2.0f + redc - bluec else 4.0f + greenc - redc
        hue = hue / 6.0f
        if (hue < 0) hue = hue + 1.0f
    }
    hsbvals[0] = hue
    hsbvals[1] = saturation
    hsbvals[2] = brightness
    return hsbvals
}

fun HSBtoRGB(hue: Float, saturation: Float, brightness: Float): Int {
    var r = 0
    var g = 0
    var b = 0
    if (saturation == 0f) {
        b = (brightness * 255.0f + 0.5f).toInt()
        g = b
        r = g
    } else {
        val h = (hue - floor(hue.toDouble()).toFloat()) * 6.0f
        val f = h - floor(h.toDouble()).toFloat()
        val p = brightness * (1.0f - saturation)
        val q = brightness * (1.0f - saturation * f)
        val t = brightness * (1.0f - saturation * (1.0f - f))
        when (h.toInt()) {
            0 -> {
                r = (brightness * 255.0f + 0.5f).toInt()
                g = (t * 255.0f + 0.5f).toInt()
                b = (p * 255.0f + 0.5f).toInt()
            }
            1 -> {
                r = (q * 255.0f + 0.5f).toInt()
                g = (brightness * 255.0f + 0.5f).toInt()
                b = (p * 255.0f + 0.5f).toInt()
            }
            2 -> {
                r = (p * 255.0f + 0.5f).toInt()
                g = (brightness * 255.0f + 0.5f).toInt()
                b = (t * 255.0f + 0.5f).toInt()
            }
            3 -> {
                r = (p * 255.0f + 0.5f).toInt()
                g = (q * 255.0f + 0.5f).toInt()
                b = (brightness * 255.0f + 0.5f).toInt()
            }
            4 -> {
                r = (t * 255.0f + 0.5f).toInt()
                g = (p * 255.0f + 0.5f).toInt()
                b = (brightness * 255.0f + 0.5f).toInt()
            }
            5 -> {
                r = (brightness * 255.0f + 0.5f).toInt()
                g = (p * 255.0f + 0.5f).toInt()
                b = (q * 255.0f + 0.5f).toInt()
            }
        }
    }
    return -0x1000000 or (r shl 16) or (g shl 8) or (b shl 0)
}




