package org.mkui.react

import com.macrofocus.common.properties.MutableProperty
import com.macrofocus.common.properties.PropertyEvent
import com.macrofocus.common.properties.PropertyListener
import com.macrofocus.common.selection.*
import react.useEffect
import react.useState

typealias SetState<T> = (value: T) -> Unit
fun <T> MutableProperty<T>.toState() : Pair<T, SetState<T>> {
    val pair = useState { this.value }
    val (getValue, setStateValue) = pair

    val setPropertyValue : SetState<T> = { this.value = it }

    val property = this

    useEffect {
        val listener = object : PropertyListener<T> {
            override fun propertyChanged(event: PropertyEvent<T>) {
//                if(DEBUG) println("Property value changed from " + event.oldValue + " to " + event.newValue)
                setStateValue(event.newValue)
            }
        }
        property.addPropertyListener(listener)

        cleanup { property.removePropertyListener(listener) }
    }
    return Pair(getValue, setPropertyValue)
}

fun <T> MutableSelection<T>.toState() : Pair<Array<T>, SetState<Array<T>>> {
    val pair = useState {this.toList().toTypedArray()}
    val (getValue, setStateValue) = pair

    val setPropertyValue : SetState<Array<T>> = { this.setSelectedElements(*it)}

    useEffect {
        val listener = object : SelectionListener<T> {
            override fun selectionChanged(event: SelectionEvent<T>) {
                setStateValue(event.model.toList().toTypedArray())
            }
        }
        this@toState.addSelectionListener(listener)
        cleanup { this@toState.removeSelectionListener(listener) }
    }
    return Pair(getValue, setPropertyValue)
}

fun <T> MutableSingleSelection<T>.toState() : Pair<T?, SetState<T>> {
    val pair = useState {this.selected }
    val (getValue, setStateValue) = pair

    val setPropertyValue : SetState<T> = { this.selected = it }

    useEffect {
        val listener = object : SingleSelectionListener<T> {
            override fun selectionChanged(event: SingleSelectionEvent<T>) {
                setStateValue(event.model.selected)
            }
        }
        this@toState.addSingleSelectionListener(listener)
        cleanup { this@toState.removeSingleSelectionListener(listener) }
    }
    return Pair(getValue, setPropertyValue)
}