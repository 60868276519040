package com.macrofocus.nastac

import com.macrofocus.nastac.components.Fabs
import com.macrofocus.nastac.components.NASTACModule
import com.macrofocus.nastac.components.Sidebar
import com.macrofocus.nastac.theme.ThemeModule
import com.macrofocus.nastac.ui.EFluxApp
import csstype.sx
import i18next.ReactI18next.I18nextProvider
import i18next.i18nextInit
import kotlinx.coroutines.MainScope
import mui.system.Box
import mui.system.sx
import react.*
import react.dom.client.createRoot
import web.cssom.*
import web.cssom.Auto.Companion.auto
import web.dom.document
import web.html.HTML

val mainScope = MainScope()

/**
 * Main entry point to the NASTAC client application
 */
fun main() {
    val root = document.createElement(HTML.div)
        .also { it.sx {
            position = Position.absolute
            top = 0.px
            bottom = 0.px
            left = 0.px
            right = 0.px
        } }
        .also { document.body.appendChild(it) }

    println("Bootstrapping NASTAC")
    val nastac = NASTAC()

    createRoot(root).render(
        NASTACModule.create() {
            this.nastac = nastac

            I18nextProvider {
                i18n = i18nextInit()

    //                Suspense {
    //                    fallback = ReactNode("Loading...")

                App {
                    this.dataLoader = nastac.dataLoader
                }
    //                }
    //                App {

            }
        }
    )
}

external interface AppProps : PropsWithChildren {
    var dataLoader: DataLoader
}

private val App = FC<AppProps> { props ->
    val mobileMode = false // useMediaQuery("(max-width:1px)") // 960px

    val appBar = false

    ThemeModule {
        Box {
            sx {
                display = Display.grid
                gridTemplateRows = if (appBar) {
                    array(
                        Sizes.Header.Height,
                        auto,
                    )
                } else {
                    array(
                        auto,
                    )
                }
                gridTemplateColumns = array(
                    Sizes.Sidebar.Width, auto,
                )
                gridTemplateAreas = if (appBar) {
                    GridTemplateAreas(
                        arrayOf(Area.Header, Area.Header),
                        if (mobileMode)
                            arrayOf(Area.Content, Area.Content)
                        else
                            arrayOf(Area.Sidebar, Area.Content),
                    )
                } else {
                    GridTemplateAreas(
                        if (mobileMode)
                            arrayOf(Area.Content, Area.Content)
                        else
                            arrayOf(Area.Sidebar, Area.Content),
                    )
                }
            }

            if (appBar) {
//                Header()
            } else {
//                if (mobileMode) Menu() else
                    Sidebar()
            }
            if (!appBar) {
                Fabs()
//                Fabbar()
            }
        }

//        AppContextProvider {
//            dataLoader = props.dataLoader
            EFluxApp {
                dataLoader = props.dataLoader
            }
//        }
    }
}