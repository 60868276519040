package com.macrofocus.common.json

/**
 * JSO backed implementation of JsonValue.
 */

actual external interface JsonValue {
}

//actual external interface JsonValue {
//    override fun asBoolean(): Boolean {
//        return unsafeCast<Boolean>()
//    }
//
//    override fun asNumber(): Double {
//        return unsafeCast<Double>()
//    }
//
//    override fun asString(): String? {
//        return unsafeCast<String>()
//    }
//
//    override val type: JsonType
//        get() {
//            if (isNull(this)) {
//                return JsonType.NULL
//            }
//            val jsType = getJsType(this)
//            if ("string".equals(jsType)) {
//                return JsonType.STRING
//            } else if ("number".equals(jsType)) {
//                return JsonType.NUMBER
//            } else if ("boolean".equals(jsType)) {
//                return JsonType.BOOLEAN
//            } else if ("object".equals(jsType)) {
//                return if (isArray(this)) JsonType.ARRAY else JsonType.OBJECT
//            }
//            println("Unknown Json Type")
//            return return JsonType.NULL
//        }
//
//    override fun jsEquals(value: JsonValue?): Boolean {
//        return js("this === value")
//    }
//
//    override fun toJson(): String? {
//        return JSON.stringify(this)
//    }
//
//    override fun toNative(): Any? {
//        return this
//    }
//
//    companion object {
//        fun getJsType(obj: Any?): String {
//            return jsTypeOf(obj)
//        }
//
//        fun isArray(obj: Any?): Boolean {
//            return js("obj === '[object Array]'")
//        }
//
//        private fun isNull(jsonValue: JsonValue?): Boolean {
//            return js("jsJsonValue === null")
//        }
//    }
/**
 * Coerces underlying value to boolean according to the rules of Javascript coercion.
 */
actual fun JsonValue.asBoolean(): Boolean {
    return this.unsafeCast<Boolean>()
}

/**
 * Coerces the underlying value to a number according to the rules of Javascript coercion.
 */
actual fun JsonValue.asNumber(): Double {
    return this.unsafeCast<Double>()
}

/**
 * Coerces the underlying value to a String according to the rules of JavaScript coercion.
 */
actual fun JsonValue.asString(): String? {
    return this.unsafeCast<String?>()
}

actual fun JsonValue.asJsonArray(): JsonArray? {
    return this.unsafeCast<JsonArray?>()
}

actual fun JsonValue.asJsonObject(): JsonObject? {
    return this.unsafeCast<JsonObject?>()
}

/**
 * Returns an enumeration representing the fundamental JSON type.
 */
actual val JsonValue.type: JsonType
    get() {
        if (isNull(this)) {
            return JsonType.NULL
        }
        val jsType = jsTypeOf(this)
        if ("string".equals(jsType)) {
            return JsonType.STRING
        } else if ("number".equals(jsType)) {
            return JsonType.NUMBER
        } else if ("boolean".equals(jsType)) {
            return JsonType.BOOLEAN
        } else if ("object".equals(jsType)) {
            return if (isArray(this)) JsonType.ARRAY else JsonType.OBJECT
        }
        println("Unknown Json Type")
        return return JsonType.NULL
    }

fun isArray(obj: Any?): Boolean {
    return obj is Array<*>
//    return js("obj === '[object Array]'")
}

private fun isNull(jsonValue: JsonValue?): Boolean {
    return js("jsonValue === null")
}

/**
 * Returns a serialized JSON string representing this value.
 * @return
 */
actual fun JsonValue.toJson(): String? {
    return kotlin.js.JSON.stringify(this)
}

/**
 * Equivalent of Javascript '==' operator comparison between two values.
 */
actual fun JsonValue.jsEquals(value: JsonValue?): Boolean {
    return js("this === value")
}

/**
 * If used in a GWT context (dev or prod mode), converts the object to a native JavaScriptObject
 * suitable for passing to JSNI methods. Otherwise, returns the current object in other contexts,
 * such as server-side use.
 */
actual fun JsonValue.toNative(): Any? {
    return this
}
//}

