package com.macrofocus.nastac

import com.macrofocus.common.json.JsonObject
import com.macrofocus.common.properties.MutableProperty
import com.macrofocus.common.properties.PropertyEvent
import com.macrofocus.common.properties.PropertyListener
import com.macrofocus.common.properties.SimpleProperty
import com.macrofocus.common.selection.MutableSelection
import com.macrofocus.common.selection.SimpleSelection
import com.macrofocus.nastac.data.Capital
import com.macrofocus.nastac.data.Country
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.LocalDate
import kotlinx.datetime.periodUntil
import org.molap.dataframe.DataFrame
import org.molap.dataframe.DefaultDataFrame
import org.molap.dataframe.WrappedDataFrame
import org.molap.geojson.GeoJSONDataFrame
import org.molap.subset.SubsetDataFrame
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.svg.SVGElement
import react.createRef
import kotlin.coroutines.*
import kotlin.js.Date

fun launch(block: suspend () -> Unit) {
    block.startCoroutine(object : Continuation<Unit> {
        override val context = EmptyCoroutineContext
        override fun resumeWith(result: Result<Unit>) = Unit
    })
}

suspend fun delay(ms: Long): Unit = suspendCoroutine { continuation ->
    setTimeout({
        continuation.resume(Unit)
    }, ms)
}

//Use top level setTimeout function instead of window.setTimeout to use with node.js
external fun setTimeout(function: () -> Unit, delay: Long)

class DataLoader {
    val dateString: MutableProperty<String> = SimpleProperty("1500-01-01")
    val divRef = createRef<HTMLDivElement>()
    val svgRef = createRef<SVGElement>()

    val shiftValue: MutableProperty<Int> = SimpleProperty(1500)
    val localShiftValue: MutableProperty<Int> = SimpleProperty(1500)

    init {
        shiftValue.addPropertyListener(object : PropertyListener<Int> {
            override fun propertyChanged(event: PropertyEvent<Int>) {
                localShiftValue.value = event.newValue
            }
        })
    }

//    val subsetDataFrame: MutableProperty<SubsetDataFrame<Int, String, Any?>?> = SimpleProperty(null)

    val dnd: MutableProperty<Boolean> = SimpleProperty(false)

    val countrySelection: MutableProperty<String?> = SimpleProperty(null as String?)
    val pointSelection: MutableSelection<Capital> = SimpleSelection()
    val pathSelection: MutableSelection<Country> = SimpleSelection()

    val threeDZoom: MutableProperty<Double> = SimpleProperty(4.0)
    val threeDLatitude: MutableProperty<Double> = SimpleProperty(48.2082) // 47.021278030856564
    val threeDLongitude: MutableProperty<Double> = SimpleProperty(7.969664962869274)
    val threeDPitch: MutableProperty<Double> = SimpleProperty(60.0) // 60
    val threeDBearing: MutableProperty<Double> = SimpleProperty(0.0)

    val lowerPercentile: MutableProperty<Double> = SimpleProperty(0.0)
    val coverage: MutableProperty<Double> = SimpleProperty(0.7)
    val radius: MutableProperty<Double> = SimpleProperty(30000.0)
    val elevationScale: MutableProperty<Double> = SimpleProperty(500.0)

    val borders: MutableProperty<Boolean> = SimpleProperty(true)
    val ethnicity: MutableProperty<Boolean> = SimpleProperty(false)
    val population: MutableProperty<Boolean> = SimpleProperty(false)
    val railway: MutableProperty<Boolean> = SimpleProperty(false)
    val partition: MutableProperty<Boolean> = SimpleProperty(false)
    val segments: MutableProperty<Boolean> = SimpleProperty(false)

    val depthTest: MutableProperty<Boolean> = SimpleProperty(true)

    val viewStyle: MutableProperty<ViewStyle> = SimpleProperty(ViewStyle.TILLY)

    init {
        // Get parameters from request.
        val url = window.location.href
        val parametersPart = url.substringAfter("?").split('&')
        val parameters = mutableMapOf(
            "date" to null as String?,
            "select" to null as String?,
            "animate" to null as String?,
        )
        parametersPart.forEach {
            val parsedParameter = it.split("=", limit = 2)
            if (parsedParameter.size == 2) {
                val (key, value) = parsedParameter
                parameters[key] = value
            }
        }

        parameters["dnd"]?.let { dnd.value = it.toBoolean() }

        val dateAsString = parameters["date"]
        if (dateAsString != null && dateAsString != "latest" && dateAsString != "earliest") {
            dateString.value = dateAsString
        }


        val dataLoader = this
        viewStyle.addPropertyListener(object : PropertyListener<ViewStyle> {
            override fun propertyChanged(event: PropertyEvent<ViewStyle>) {
                event.newValue.apply(dataLoader)
            }
        })
        viewStyle.value.apply(this)

        // GeoJSON
//        GlobalScope.launch {
//            // Initialize date
//            val json = window.fetch("api/").await().json().await()
//            val dataFrame = GeoJSONDataFrame(json.unsafeCast<JsonObject>())
//            dataFrame.printSchema()
//
//            val derived = WrappedDataFrame(dataFrame)
////    derived.addOriginalColumns()
//            derived.addOriginalColumn("Year")
//            derived.addOriginalColumn("Id")
//            derived.addOriginalColumn("Name")
//            derived.addOriginalColumn("Area")
//            derived.addOriginalColumn("Capital")
//            derived.addOriginalColumn("geometry")
//            derived.addDerivedColumn(object : WrappedDataFrame.DerivedColumn<Int,String?,Any?>("startdate", LocalDate::class) {
//                override fun getValue(dataFrame: WrappedDataFrame<Int, String?, Any?>, r: Int): Any? {
//                    val value = dataFrame.getValueAt(r, "Year")
//                    if(value is String) {
//                        val year = value.substring(0, 4).toInt()
//                        val month = value.substring(5, 7).toInt()
//                        val day = value.substring(8, 10).toInt()
//                        val date = LocalDate(year, month, day)
//                        return date
//                    } else if(value is Number) {
//                        val year = value.toInt()
//                        val month = 1
//                        val day = 1
//                        val date = LocalDate(year, month, day)
//                        return date
//                    } else {
//                        return null
//                    }
//                }
//            })
//            derived.addDerivedColumn(object : WrappedDataFrame.DerivedColumn<Int,String?,Any?>("enddate", LocalDate::class) {
//                override fun getValue(dataFrame: WrappedDataFrame<Int, String?, Any?>, r: Int): Any? {
//                    val value = dataFrame.getValueAt(r, "Year")
//                    if(value is String) {
//                        val year = value.substring(0, 4).toInt()
//                        val month = value.substring(5, 7).toInt()
//                        val day = value.substring(8, 10).toInt()
//                        val date = LocalDate(year, month, day)
//                        return date
//                    } else if(value is Number) {
//                        val year = value.toInt()
//                        val month = 12
//                        val day = 31
//                        val date = LocalDate(year, month, day)
//                        return date
//                    } else {
//                        return null
//                    }
//                }
//            })
//            derived.addDerivedColumn(object : WrappedDataFrame.DerivedColumn<Int,String?,Any?>("period", DatePeriod::class) {
//                override fun getValue(dataFrame: WrappedDataFrame<Int, String?, Any?>, r: Int): Any? {
//                    val start = dataFrame.getValueAt(r, "startdate")
//                    val end = dataFrame.getValueAt(r, "enddate")
//                    if(start is LocalDate && end is LocalDate) {
//                        val date = start.periodUntil(end)
//                        return date
//                    } else {
//                        return null
//                    }
//                }
//            })
//            val typed = DefaultDataFrame(derived as DataFrame<Any?, Any?, Any?>)
//
//            val subset = SubsetDataFrame(typed)
//            println(subset.getColumnClass("startdate"))
//            subset.getOrdinalDimension("startdate").filterRange(LocalDate(1816, 1, 1), LocalDate(2000, 1, 1))
//            subset.getOrdinalDimension("enddate").filterRange(LocalDate(2000, 1, 1), LocalDate(2017, 12, 31))
//            println("DataFrame: " + dataFrame.rowCount + "x" + dataFrame.columnCount)
//
//            subsetDataFrame.value = subset
//        }
//
//        dateString.addPropertyListener(object : PropertyListener<String> {
//            override fun propertyChanged(event: PropertyEvent<String>) {
//                val current = dateString.value.toRawDate()
////                val subset = world.value?.cshapes
//                if(subsetDataFrame.value != null) {
//                    println(subsetDataFrame.value!!.getColumnClass("startdate"))
//                    subsetDataFrame.value!!.getOrdinalDimension("startdate").filterRange(LocalDate(1816, 1, 1), LocalDate(current.getFullYear(), current.getMonth() + 1, current.getDate()))
//                    subsetDataFrame.value!!.getOrdinalDimension("enddate").filterRange(LocalDate(current.getFullYear(), current.getMonth() + 1, current.getDate()), LocalDate(2017, 12, 31))
//                }
//            }
//        })

        parameters["animate"]?.let {
            val ms = it.toLong()
            println("Starting animation with 5 minutes = " + ms + " ms")
            launch {
                while (true) {
                    delay(ms)
                    val current = dateString.value.toRawDate()
                    val next = Date(current.getTime() + 1000 * 60 * 60 * 24)
                    println("Next date: " + next.toRawString())
                    dateString.value = next.toRawString()
                }
            }
        }
    }
}
