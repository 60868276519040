package com.macrofocus.nastac.events

import com.macrofocus.nastac.ui.PaletteType
import mui.material.Card
import mui.material.PaletteMode
import mui.material.styles.Theme
import mui.material.styles.useTheme
import mui.system.sx
import react.FC
import react.PropsWithChildren
import react.PropsWithRef
import web.cssom.rgb
import web.html.HTMLDivElement

external interface SectionProps : PropsWithChildren, PropsWithRef<HTMLDivElement> {
    var title: String?
}

val Section = FC<SectionProps> { props ->
    val theme = useTheme<Theme>()
    val paletteType = if (theme.asDynamic().palette.mode == "dark") PaletteType.dark else PaletteType.light
    Card {
        sx {
//                position = Position.absolute

            backgroundColor = if (theme.palette.mode == PaletteMode.light) rgb(255, 255, 255, 0.7) else rgb(0, 0, 0, 0.7)
        }

        if(props.title != null) {
            Header {
                title = props.title!!
                this.ref = props.ref
            }
        }

        +props.children
    }
}
