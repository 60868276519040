package com.macrofocus.nastac.events

import com.macrofocus.common.properties.MutableProperty
import com.macrofocus.nastac.events.Event
import com.macrofocus.nastac.DataLoader
import com.macrofocus.nastac.theme.ThemeContext
import csstype.*
import emotion.react.css
import i18next.invoke
import i18next.useTranslation
import js.core.jso
import mui.material.*
import mui.system.sx
import react.*
import web.cssom.*
import web.events.EventType
import web.html.HTMLDivElement

external interface EventDialogProps : PropsWithChildren {
    var configuration: DataLoader

    var event: Event
    var mode: EventMode

    var open: Boolean
    var onClose: ((event: dynamic, reason: String) -> Unit)?
}

val EventDialog = FC<EventDialogProps> { props ->
    var theme by useContext(ThemeContext)!!
    val (t, _) = useTranslation()

    var (mode, setMode) = useState(props.mode)

    val callback = useRefCallback<HTMLDivElement> {div ->
        println("Registering scroll on $div, class=${div?.className}")

        if (div != null) {
            val callback: (event: web.events.Event) -> Unit = { event ->
                val animation = props.event.animation(props.configuration)

                if(mode != EventMode.ANIMATED) {
                    val e = event.target.asDynamic()
                    val zoom = ((e.scrollTop) / (e.scrollHeight - e.clientHeight)).unsafeCast<Double>()

                    animation?.tick((zoom * 1000.0).toLong())
                }
            }
            div.addEventListener(EventType("scroll"), callback)
        }
    }

    if(props.open) {
        Drawer {
            this.open = props.open
            onClose = props.onClose

            variant = DrawerVariant.permanent
            anchor = DrawerAnchor.right

            sx = jso {
                textTransform = None.none
                //                flexGrow = number(1.0)
                //                flexShrink = number(1.0)
                //                height = 100.pct - Sizes.Header.height
                //                backdropFilter

                position = Position.fixed
                right = 16.px
                top = 76.px
            }

            PaperProps = jso {
                sx = jso {
                    //                    boxSizing = BoxSizing.borderBox
                    //                    position = Position.sticky

                    // Prevent the sidebar from extending to the bottom
                    height = Globals.unset

                    backgroundColor =
                        if (theme.palette.mode == PaletteMode.light) rgb(255, 255, 255, 0.5) else rgb(0, 0, 0, 0.2)
                    backgroundImage = if (theme.palette.mode == PaletteMode.light)
                        linearGradient(
                            stop(rgb(0, 0, 0, 0.08), 0.pct),
                            stop(rgb(0, 0, 0, 0.01), 100.pct)
                        )
                    else
                        linearGradient(
                            stop(rgb(255, 255, 255, 0.08), 0.pct),
                            stop(rgb(255, 255, 255, 0.01), 100.pct)
                        )
                    //                    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
                    boxShadow = BoxShadow(0.px, 3.px, 5.px, -1.px, rgb(0, 0, 0, 0.2))
                    backdropFilter = blur(4.px)

                    borderRight = Globals.unset
                    position = Position.absolute
                    left = Globals.unset
                    top = Globals.unset
                }
            }


            css {
                backgroundColor = rgb(255, 255, 255, 0.6)
            }

//            DialogTitle {
                //            css {
                //                textTransform = None.none
                //            }

//                +"Ereignis im 220-kV-Unterwerk Laufenburg"
//            }

            // MuiDialogContent-root
            DialogContent {
                this.ref = callback

                sx {
                    height = 300.px
                }

                if(props.event != null) {
                    +props.event!!.reactElement(props, mode)
                }
            }

            DialogActions {
//                Button {
//                    onClick = {
//                        props.efluxNetwork.wait {
//                            val network = it!!
//                            val timeline = timeline {
//                                //                            parallel {
//                                //                                zoom(it, initialScale, 10.0, 30000, Easing.LINEAR) {
//                                //                                    duration = 30000
//                                //                                }
//                                //                            }
//                                parallel {
//                                    zoomAtPoint(
//                                        network.graphicalNodes[logicalBusIdOf("SLAUFE2")],
//                                        props.svgPanZoomContext.value!!,
//                                        8.0,
//                                        7500,
//                                        Easing.LINEAR
//                                    )
//                                }
//                                parallel {
//                                    zoom(props.svgPanZoomContext.value!!, 1.0, 7500, Easing.LINEAR)
//                                }
//                            }
//
//                            setMode(EventMode.ANIMATED)
//                            timeline.start()
//                        }
//
////                        val to = props.efluxNetwork.value?.graphicalNodes?.get(logicalBusIdOf("SLAUFE2"))
////                        val animation = PanToAnimation(to, props.svgPanZoomContext.value!!, 1000)
////                        animation.tick(1000)
//
////                        if (to != null) {
////                            val center = to.screenPanZoomCenter
////                            println("pan on ${center.x},${center.y}")
////
////                            props.svgPanZoomContext.value?.zoomAtPoint(2.0, center, false)
////                        }
//                    }
//                    +"Animate"
//                }
//                FormGroup {
//                    FormControlLabel {
//                        label = ReactNode("Manual")
//                        control = Switch.create {
//                            value = mode == EventMode.MANUAL
//                            onClick = {
//                                if (mode == EventMode.MANUAL) {
//                                    setMode(EventMode.AUTO)
//                                } else {
//                                    setMode(EventMode.MANUAL)
//                                }
//                            }
//                        }
//                    }
//                }
                Button {
                    onClick = {
                        props.onClose?.let { it1 -> it1(it, "click") }
                        props.configuration.pointSelection.clearSelection()
                        props.configuration.pathSelection.clearSelection()
                    }
                    +t("appBar.close")
                }
            }
        }
    }
}

