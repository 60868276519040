package com.macrofocus.nastac.ui

import com.macrofocus.common.json.JsonArray
import com.macrofocus.common.json.JsonObject
import com.macrofocus.nastac.DataLoader
import com.macrofocus.nastac.data.GeoEPRAgg
import com.macrofocus.nastac.data.HEG
import com.macrofocus.nastac.theme.ThemeContext
import com.macrofocus.nastac.ui.react.toState
import deckgl.*
import deckgl.Layer
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.async
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import js.core.jso
import map.ReactMapGL
import org.mkui.color.colorOf
import org.mkui.color.crossplatform.darker
import org.mkui.colormap.ColorMapFactory
import org.mkui.palette.PaletteFactory
import org.molap.dataframe.DataFrame
import org.molap.geojson.GeoJSONDataFrame
import react.*
import react.dom.html.ReactHTML
import web.cssom.Position
import web.cssom.pct
import web.html.HTMLDivElement

external interface Layer {
}
external interface Paint {
}

external interface ThreeDProps : Props {
    var dataLoader: DataLoader
//    var viewLoader: ViewLoader
    var show: Boolean
}

public val ThreeD = FC<ThreeDProps> { props ->
    if(!props.show) {
        return@FC
    }

    val MAPLIBRE = false

    var theme by useContext(ThemeContext)!!
    val paletteType = if(theme.asDynamic().palette.mode == "dark") PaletteType.dark else PaletteType.light

    val (threeDLatitude, _) = props.dataLoader.threeDLatitude.toState()
    val (threeDLongitude, _) = props.dataLoader.threeDLongitude.toState()
    val (threeDZoom, _) = props.dataLoader.threeDZoom.toState()
    val (threeDPitch, _) = props.dataLoader.threeDPitch.toState()
    val (threeDBearing, _) = props.dataLoader.threeDBearing.toState()

    val (year, _) = props.dataLoader.shiftValue.toState()

    val (borders, _) = props.dataLoader.borders.toState()
    val (ethnicity, _) = props.dataLoader.ethnicity.toState()
    val (railway, _) = props.dataLoader.railway.toState()
    val (population, _) = props.dataLoader.population.toState()

    val (lowerPercentile, _) = props.dataLoader.lowerPercentile.toState()
    val (radius, _) = props.dataLoader.radius.toState()
    val (coverage, _) = props.dataLoader.coverage.toState()
    val (elevationScale, _) = props.dataLoader.elevationScale.toState()

    val (depthTest, _) = props.dataLoader.depthTest.toState()

    val (getBuses, setBuses) = useState<GeoJSONDataFrame>()
    val (getBranches, setBranches) = useState<GeoJSONDataFrame>()

//    useEffectOnce {
//        GlobalScope.launch {
//            window.fetch("/Unterwerke.geojson").then {
//                async { setBuses(GeoJSONDataFrame(it.json().await().unsafeCast<JsonObject>())) }
//            }.await().await()
//        }
//
//        GlobalScope.launch {
//            window.fetch("/Leitungen.geojson").then {
//                async { setBranches(GeoJSONDataFrame(it.json().await().unsafeCast<JsonObject>())) }
//            }.await().await()
//        }
//    }

    val INITIAL_VIEW_STATE : dynamic = jso {
//        latitude = 47.021278030856564 // 47.65
//        longitude = 7.969664962869274 // 7
//        zoom = 3 // 9.333879434342306 // 6
//        pitch = 60 // 50
//        bearing = 0

        latitude = threeDLatitude // 47.021278030856564
        longitude = threeDLongitude // 7.969664962869274
        zoom = threeDZoom // 3
        pitch = threeDPitch // 60
        bearing = threeDBearing // 0

//        maxZoom = 16
    }

    val color = arrayOf(255, 140, 0)

    val radiusFunction: (dynamic) -> Double = { d: dynamic -> 100.0 }
    val positionFunction = { d: dynamic -> d.COORDINATES }
    val colorFunction = { d: dynamic ->
//        arrayOf(0, 140, 255)
        d.color
    }
    val darkColorFunction = { d: dynamic ->
//        arrayOf(0, 140, 255)
        val c = colorOf(d.color[0] as Int, d.color[1] as Int, d.color[2] as Int).darker()
        arrayOf(c.getRed().toInt(), c.getGreen().toInt(), c.getBlue().toInt())
    }

    val arcSourceColorFunction = { d: dynamic ->
        val z = d.start[2] as Int;
        val r = z / 10000;

        arrayOf(255 * (1 - r * 2), 128 * r, 255 * r, 255 * (1 - r))
    }
    val widthFunction = { d: dynamic -> d.thickness }

    val doubleWidthFunction = { d: dynamic -> d.thickness * 2.0 }

    val (populationData, setPopulationData) = useState(null as Array<dynamic>?)
    useEffect(year, population) {
        if(population) {
            GlobalScope.launch {
                val dataFrame = window.fetch("/raster/popc/$year").then {
                    async { GeoJSONDataFrame(it.json().await().unsafeCast<JsonObject>()) }
                }.await().await()
                val getCoordinates: (Int, DataFrame<Int, String?, Any?>) -> JsonArray? = { row, dataFrame ->
                    val x = dataFrame.getValueAt(row, "Longitude") as Double?
                    val y = dataFrame.getValueAt(row, "Latitude") as Double?
                    arrayOf(x, y).unsafeCast<JsonArray>()
                }
                val array = ArrayList<dynamic>()
                for (r in dataFrame.rows()) {
                    val coordinates = getCoordinates(r, dataFrame)
                    val amount = dataFrame.getValueAt(r, "Value") as Double?

                    if (coordinates != null && amount != null) {
                        val p = jso<dynamic> {
                            COORDINATES = coordinates
                            WEIGHT = amount
                        }
                        array.add(p)
                    }
                }
                setPopulationData(array.toTypedArray())
            }
        }
    }

//    val busesLayer = ScatterplotLayer(jso {
//        id = "busesLayer"
//        this.data = busData
//        radiusScale = 15
//        getRadius = radiusFunction
//        getPosition = positionFunction
//        getFillColor = { d : dynamic -> d.color }
//        opacity = 0.25
//    })

//    val branchesLayer = LineLayer<Any>(jso {
//        id = "branchesLayer"
//        this.nodeHeatmapData = branchData
//
//        opacity = 0.8
//        getSourcePosition = { d: dynamic -> d.start }
//        getTargetPosition = { d: dynamic -> d.end }
//        getColor = colorFunction
//        getWidth = widthFunction
//    })

//    val arcLayer = ArcLayer<Any>(jso {
//        id = "arcLayer"
//        this.data = branchData
//
//        opacity = 0.7
//        getSourcePosition = { d: dynamic -> d.start }
//        getTargetPosition = { d: dynamic -> d.end }
//        getSourceColor = colorFunction
//        getTargetColor = colorFunction
//        getWidth = widthFunction
//    })

//    val busesGeoJsonLayer = GeoJsonLayer(jso {
//        id = "buses"
//        this.nodeHeatmapData = "/Unterwerke.geojson"
//
//        pickable = true
//        stroked = false
//        filled = true
//        extruded = true
//        pointType = "circle"
//        lineWidthScale = 20
//        lineWidthMinPixels = 2
//        getFillColor = arrayOf(160, 160, 180, 200)
////        getLineColor: d => colorToRGBArray(d.properties.color),
//        getPointRadius = 100
//        getLineWidth = 1
//        getElevation = 30
//    })

//    val first = GeoJsonLayer(jso {
//        id = "first"
//        this.data = "/first/${year}"
//
//        pickable = true
//        stroked = false
//        filled = true
//        extruded = true
//        pointType = "circle"
//        lineWidthScale = 20
//        lineWidthMinPixels = 2
//        getFillColor = arrayOf(255, 0, 0, 200)
//        getLineColor = { d : dynamic -> arrayOf(0,0,0, 200) }
//        getPointRadius = 100
//        getLineWidth = 10
//        getElevation = 30
//    })
//
//    val core = GeoJsonLayer(jso {
//        id = "core"
//        this.data = "/core/${year}"
//
//        pickable = true
//        stroked = false
//        filled = true
//        extruded = true
//        pointType = "circle"
//        lineWidthScale = 20
//        lineWidthMinPixels = 2
//        getFillColor = arrayOf(255, 0, 0, 200)
//        getLineColor = { d : dynamic -> arrayOf(0,0,0, 200) }
//        getPointRadius = 100
//        getLineWidth = 10
//        getElevation = 30
//    })

    val shape = MVTLayer<Any>(jso {
        id = "shape-$year"
        this.data = "/martin/shape/${year}"

//        pickable = true
//        autoHighlight = true
        asDynamic().uniqueIdProperty = "id"
        asDynamic().stroked = false
        asDynamic().filled = true
        asDynamic().extruded = false
        asDynamic().pointType = "circle"
        asDynamic().lineWidthScale = 20
        asDynamic().lineWidthMinPixels = 2
        asDynamic().getFillColor = arrayOf(0xEE, 0xD5, 0xB7, 127)
//        getLineColor = { d : dynamic -> arrayOf(0,0,0, 200) }
        asDynamic().getLineColor = null
        asDynamic().getPointRadius = 100
        asDynamic().getLineWidth = 10
        asDynamic().getElevation = 10
//        asDynamic().onOver = { info: dynamic ->
//            println("onOver: $info")
//        }
//        asDynamic().onClick = {info: dynamic ->
//            console.log("Click", info)
//            props.dataLoader.countrySelection.value = info.`object`.properties.Name
//        }
    })

    val growth = MVTLayer<Any>(jso {
        id = "growth-$year"
        this.data = "/martin/growth/${year}"

        pickable = false
        asDynamic().stroked = false
        asDynamic().filled = true
        asDynamic().extruded = true
        asDynamic().pointType = "circle"
        asDynamic().lineWidthScale = 20
        asDynamic().lineWidthMinPixels = 2
        asDynamic().getFillColor = arrayOf(255, 0, 0, 200)
//        getLineColor = { d : dynamic -> arrayOf(0,0,0, 200) }
        asDynamic().getLineColor = null
        asDynamic().getPointRadius = 100
        asDynamic().getLineWidth = 10
        asDynamic().getElevation = 30
    })

    val nullius = MVTLayer<Any>(jso {
        id = "nullius-$year"
        this.data = "/martin/nullius/${year}"

        pickable = false
        asDynamic().stroked = false
        asDynamic().filled = true
        asDynamic().extruded = false
        asDynamic().pointType = "circle"
        asDynamic().lineWidthScale = 20
        asDynamic().lineWidthMinPixels = 2
        asDynamic().getFillColor = arrayOf(0, 0, 255, 200)
//        getLineColor = { d : dynamic -> arrayOf(0,0,0, 200) }
        asDynamic().getLineColor = null
        asDynamic().getPointRadius = 100
        asDynamic().getLineWidth = 10
        asDynamic().getElevation = 100
    })

    val war = MVTLayer<Any>(jso {
        id = "war-$year"
        this.data = "/martin/war/${year}"

        pickable = false
        asDynamic().stroked = false
        asDynamic().filled = true
        asDynamic().extruded = false
        asDynamic().pointType = "circle"
        asDynamic().lineWidthScale = 20
        asDynamic().lineWidthMinPixels = 2
        asDynamic().getFillColor = arrayOf(255, 0, 0, 200)
//        getLineColor = { d : dynamic -> arrayOf(0,0,0, 200) }
        asDynamic().getLineColor = null
        asDynamic().getPointRadius = 100
        asDynamic().getLineWidth = 10
        asDynamic().getElevation = 200
    })

    val peace = MVTLayer<Any>(jso {
        id = "peace-$year"
        this.data = "/martin/peace/${year}"

        pickable = false
        asDynamic().stroked = false
        asDynamic().filled = true
        asDynamic().extruded = false
        asDynamic().pointType = "circle"
        asDynamic().lineWidthScale = 20
        asDynamic().lineWidthMinPixels = 2
        asDynamic().getFillColor = arrayOf(0x66, 0xCD, 0x00, 200)
//        getLineColor = { d : dynamic -> arrayOf(0,0,0, 200) }
        asDynamic().getLineColor = null
        asDynamic().getPointRadius = 100
        asDynamic().getLineWidth = 10
        asDynamic().getElevation = 300
    })

    val border = MVTLayer<Any>(jso {
        id = "border-$year"
        this.data = "/martin/shape/${year}"

        pickable = false
        asDynamic().stroked = true
        asDynamic().filled = false
        asDynamic().extruded = false
        asDynamic().pointType = "circle"
//        lineWidthScale = 20
        asDynamic().lineWidthMinPixels = 1
        asDynamic().lineWidthMaxPixels = 4
        asDynamic().getFillColor = null
        asDynamic().getLineColor = { d : dynamic -> arrayOf(0,0,0, 127) }
        asDynamic().getPointRadius = 100
        asDynamic().getLineWidth = 5000
        asDynamic().getElevation = 30

        asDynamic().onOver = { info: dynamic ->
            println("onOver: $info")
        }
    })

    val pickable = MVTLayer<Any>(jso {
        id = "pickable-$year"
        this.data = "/martin/shape/${year}"

        pickable = true
        autoHighlight = true
        asDynamic().uniqueIdProperty = "id"
        asDynamic().stroked = true
        asDynamic().filled = true
        asDynamic().extruded = false
        asDynamic().pointType = "circle"
//        lineWidthScale = 20
        asDynamic().lineWidthMinPixels = 1
        asDynamic().lineWidthMaxPixels = 4
        asDynamic().getFillColor = arrayOf(0xEE, 0xD5, 0xB7, 254)
        asDynamic().getLineColor = { d : dynamic -> arrayOf(0,0,0, 127) }
        asDynamic().getPointRadius = 100
        asDynamic().opacity = 0
        asDynamic().getLineWidth = 5000
        asDynamic().getElevation = 30

        asDynamic().onOver = { info: dynamic ->
            println("onOver: $info")
        }
        asDynamic().onClick = {info: dynamic ->
            console.log("Click", info)
            props.dataLoader.countrySelection.value = info.`object`.properties.Name
        }
    })

    val paletteFactory = PaletteFactory.instance
    val colorMapFactory = ColorMapFactory(paletteFactory)

    val palette = paletteFactory.createDefaultQualititativePalette()
//    val interpolated = InterpolatedPalette(palette)
//    interpolated.mode.value = InterpolatedPalette.Mode.Bands
    val colormap = colorMapFactory.createCategoricalColorMap(HEG.ethnicNames.union(GeoEPRAgg.ethnicNames), false, palette)
    colormap.setColor("italian [org]", colorOf(180, 0, 180))
    colormap.setColor("german [l5]", colorOf(127, 127, 127))
//    colormap.setColor("croatian [org]", colorOf(127, 127, 127))
    colormap.setColor("greek [l2]", colorOf(128, 64, 0))
    colormap.setColor("serbian [org]", colorOf(0, 0, 255))

    val function = { d: dynamic ->
        val ethnicName = d.properties.EthnicName as String
        val color = colormap.getColor(ethnicName)
        if(color != null) {
            println("$ethnicName -> ${color.toHTMLColor()}")
            arrayOf(color.r.toInt(), color.g.toInt(), color.b.toInt(), 127)
        } else {
            arrayOf(127, 127, 127, 127)
        }
//        if (ethnicName.startsWith("f"))
//            arrayOf(255, 127, 127, 127) else arrayOf(127, 127, 127, 127)
    }

    val heg = MVTLayer<Any>(jso {
        id = "heg-$year"
        this.data = "/martin/heg/${year}"

        this.pickable = false
        asDynamic().stroked = false
        asDynamic().filled = true
        asDynamic().extruded = false
        asDynamic().pointType = "circle"
//        lineWidthScale = 20
        asDynamic().lineWidthMinPixels = 1
        asDynamic().getFillColor = function
        asDynamic().getLineColor = { d : dynamic -> arrayOf(0,0,0, 127) }
        asDynamic().getPointRadius = 100
        asDynamic().getLineWidth = 1
        asDynamic().getElevation = 30
    })

    val rshapes = MVTLayer<Any>(jso {
        id = "rshapes-$year"
        this.data = "/martin/rshapes/${year}"

        this.pickable = false
        asDynamic().stroked = true
        asDynamic().filled = false
        asDynamic().extruded = false
        asDynamic().pointType = "circle"
//        lineWidthScale = 20
        asDynamic().lineWidthMinPixels = 2
        asDynamic().lineWidthMaxPixels = 4
        asDynamic().getFillColor = null
        asDynamic().getLineColor = { d : dynamic -> arrayOf(0,0,0) }
        asDynamic().getPointRadius = 100
        asDynamic().getLineWidth = 5000
        asDynamic().getElevation = 30

        asDynamic().onOver = { info: dynamic ->
            println("onOver: $info")
        }
    })

    val branchesGeoJsonLayer = GeoJsonLayer(jso {
        id = "branches"
        this.data = "/cshapes_2_gw.geojson"

        this.pickable = true
        stroked = false
        filled = true
        extruded = true
        pointType = "circle"
        lineWidthScale = 20
        lineWidthMinPixels = 2
        getFillColor = arrayOf(255, 255, 0, 200)
        getLineColor = { d : dynamic -> arrayOf(0,0,0, 200) }
        getPointRadius = 100
        getLineWidth = 10
        getElevation = 30
    })

    val RdYlBu = arrayOf(
        arrayOf(165,0,38),
        arrayOf(215,48,39),
        arrayOf(244,109,67),
        arrayOf(253,174,97),
        arrayOf(254,224,144),
        arrayOf(255,255,191, 0),
        arrayOf(224,243,248),
        arrayOf(171,217,233),
        arrayOf(116,173,209),
        arrayOf(69,117,180),
        arrayOf(49,54,149)
    ).reversedArray()

    val RdGy = arrayOf(
        arrayOf(103,0,31),
        arrayOf(178,24,43),
        arrayOf(214,96,77),
        arrayOf(244,165,130),
        arrayOf(253,219,199),
        arrayOf(255,255,255, 127),
        arrayOf(224,224,224),
        arrayOf(186,186,186),
        arrayOf(135,135,135),
        arrayOf(77,77,77),
        arrayOf(26,26,26)
    )

    val targetRef = useRef<HTMLDivElement>()

    ReactHTML.div {
        ref = targetRef
        css {
            position = Position.absolute
            this.width = 100.pct
            this.height = 100.pct
        }

        val blendFunc = arrayOf(GL.SRC_ALPHA, GL.ONE, GL.ONE_MINUS_DST_ALPHA, GL.ONE)
        val blendEquation = GL.FUNC_ADD

        // first, core, shape, growth, border, nullius, war, peace, border,
        val layerList = mutableListOf<Layer>()
        if(borders) {
            layerList.addAll(mutableListOf(shape, nullius, war, peace, border, pickable))
        }
        if(ethnicity) {
            layerList.addAll(mutableListOf<Layer>(shape, heg, border))
        }
//        val layers = mutableListOf(shape, nullius, war, peace, border)
//        val layers = mutableListOf<Layer>(shape, heg, border)

        if(population && populationData != null) {
            val colorRange = arrayOf(
                arrayOf(1, 152, 189),
                arrayOf(73, 227, 206),
                arrayOf(216, 254, 181),
                arrayOf(254, 237, 177),
                arrayOf(254, 173, 84),
                arrayOf(209, 55, 78)
            )

            if(false) {
                val layer = HeatmapLayer(jso<HeatmapLayerProps<Any?>> {
                    id = "populationLayer"
                    this.data = populationData

                    getPosition = { d: dynamic -> d.COORDINATES }
                    getWeight = { d: dynamic -> d.WEIGHT }
                    this.colorRange = colorRange
                    aggregation = "SUM"
                    radiusPixels = 12 * threeDZoom
                })
                layerList.add(layer)
            } else {
                val layer = HexagonLayer(jso<HexagonLayerProps<Any?>> {
                    id = "populationLayer"
                    this.data = populationData

                    getPosition = { d : dynamic -> d.COORDINATES }
                    getElevationWeight = { d: dynamic -> d.WEIGHT }
                    this.colorRange = colorRange
                    this.lowerPercentile = lowerPercentile
                    extruded = true
                    this.elevationScale = elevationScale
                    this.radius = radius
                    this.coverage = coverage
                })

                layerList.add(0, layer)
            }
        }

        if(railway) {
            layerList.add(rshapes)
        }

        val layers = layerList.toTypedArray()
//        val layers = arrayOf<Layer>(heg)

        DeckGL {
            initialViewState = INITIAL_VIEW_STATE
            controller = true
            this.layers = layers
            parameters = jso {
//                this.blendFunc = blendFunc
//                this.blendEquation = blendEquation
                this.depthTest = depthTest
            }
//            ContextProvider = _MapContext.Provider

            val style : dynamic = jso {
                version = 8
                if(!MAPLIBRE) {
                    sources = jso {
                        natural_earth_gray_earth_hypso_shaded_relief = jso {
                            url = "/natural_earth_gray_earth_hypso_shaded_relief"
                            type = "raster"
                        }
                    }
                } else {
                    sources = jso {
                        geocountryperiods = jso {
                            url = "/geocountryperiods/$year"
                            type = "vector"
                        }
                        natural_earth = jso {
                            url = "/natural_earth"
                            type = "raster"
                        }
                        natural_earth_2 = jso {
                            url = "/natural_earth_2"
                            type = "raster"
                        }
                        natural_earth_2_shaded_relief = jso {
                            url = "/natural_earth_2_shaded_relief"
                            type = "raster"
                        }
                        natural_earth_cross_blended_hypso = jso {
                            url = "/natural_earth_cross_blended_hypso"
                            type = "raster"
                        }
                        natural_earth_cross_blended_hypso_shaded_relief = jso {
                            url = "/natural_earth_cross_blended_hypso_shaded_relief"
                            type = "raster"
                        }
                        natural_earth_gray_earth_hypso_shaded_relief = jso {
                            url = "/natural_earth_gray_earth_hypso_shaded_relief"
                            type = "raster"
                        }
                        natural_earth_shaded_relief = jso {
                            url = "/natural_earth_shaded_relief"
                            type = "raster"
                        }
                    }
                }

                if(!MAPLIBRE) {
                    this.layers = arrayOf<dynamic>(
                        jso {
                            this.id = "background"
                            type = "background"
                            layout = jso {
                                visibility = "visible"
                            }
                            paint = jso<Any> {
                                asDynamic()["background-color"] = "rgba(6, 35, 51, 1)"
                            }
                        },
                        jso<Layer> {
                            asDynamic().id = "natural_earth_gray_earth_hypso_shaded_relief"
                            asDynamic().type = "raster"
                            asDynamic().source = "natural_earth_gray_earth_hypso_shaded_relief"
                            asDynamic().minzoom = 0.0
                            asDynamic().layout = jso {
                                visibility = "visible"
                            }
                        })
                } else {
                    this.layers = arrayOf<dynamic>(
                        jso {
                            this.id = "background"
                            type = "background"
                            layout = jso {
                                visibility = "visible"
                            }
                            paint = jso<Any> {
                                asDynamic()["background-color"] = "rgba(6, 35, 51, 1)"
                            }
                        },
                        jso<Layer> {
                            asDynamic().id = "natural_earth_2"
                            asDynamic().type = "raster"
                            asDynamic().source = "natural_earth_2"
                            asDynamic().minzoom = 0.0
                            asDynamic().layout = jso {
                                visibility = "visible"
                            }
                        },
                        jso<Layer> {
                            asDynamic().id = "countries"
                            asDynamic().type = "line"
                            asDynamic().source = "geocountryperiods"
                            asDynamic()["source-layer"] = "countries"
                            asDynamic().minzoom = 0.0
                            asDynamic().layout = jso {
                                visibility = "visible"
                            }
                            asDynamic().paint = jso<Paint> {
                                asDynamic()["line-blur"] = 1
                                asDynamic()["line-color"] = "rgba(255, 50, 50, 1)"
                                asDynamic()["line-width"] = 5
                            }
                        }
                    )
                }
//                center = arrayOf(8.265, 46.786)
//                this.zoom = 7.64
//                bearing = 0
//                pitch = 0
//                transition = jso {}
            }


            ReactMapGL {
//                this.mapLib = maplibregl
                this.mapStyle = style
//                this.mapStyle = if(paletteType == PaletteType.dark) {
//                    "style-dark.json"
//                } else {
//                    "style-light.json"
//                }
//                this.mapStyle =
//                    "https://vectortiles.geo.admin.ch/styles/ch.swisstopo.leichte-basiskarte.vt/style.json"
            }

//            NavigationControl {
//                visualizePitch = true
//                position = "bottom-right"
//            }
        }
    }
}

