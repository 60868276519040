package com.macrofocus.nastac.events

import com.macrofocus.eflux.animation.Timeline
import com.macrofocus.nastac.DataLoader
import js.core.ReadonlyArray
import mui.material.Box
import mui.material.CardContent
import mui.material.CardHeader
import mui.material.Typography
import mui.system.sx
import react.*
import react.dom.html.ReactHTML
import web.cssom.None
import web.cssom.TextDecoration
import web.dom.observers.IntersectionObserver
import web.dom.observers.IntersectionObserverEntry
import web.html.HTMLDivElement

external interface PrussiaProps : PropsWithChildren {
    var configuration: DataLoader
    var mode: EventMode
}

val Prussia = FC<PrussiaProps> { props ->
    val s1Ref = useRef<HTMLDivElement>()
    val s2Ref = useRef<HTMLDivElement>()
    val s3Ref = useRef<HTMLDivElement>()
    val s4Ref = useRef<HTMLDivElement>()
    val s5Ref = useRef<HTMLDivElement>()
    val s6Ref = useRef<HTMLDivElement>()

    var (playback, setPlayback) = useState<Timeline>()

//    val auswirkungenRef = useRefCallback<HTMLDivElement> {div ->
    useEffect(props.mode) {
        val s1 = s1Ref.current
        val s2 = s2Ref.current
        val s3 = s3Ref.current
        val s4 = s4Ref.current
        val s5 = s5Ref.current
        val s6 = s6Ref.current
        val observer = IntersectionObserver(
            { entries: ReadonlyArray<IntersectionObserverEntry>, observer: IntersectionObserver ->
                if(props.mode != EventMode.ANIMATED) {
//                    val network = props.efluxNetwork.value!!
                    for (entry in entries) {
                        when (entry.target) {
                            s1 -> {
                                if (entry.isIntersecting) {
                                    props.configuration.countrySelection.value = "Habsburgs"
                                    props.configuration.shiftValue.value = 1490
                                }
                            }

                            s2 -> {
                                if (entry.isIntersecting) {
                                    props.configuration.shiftValue.value = 1550
                                }
                            }

                            s3 -> {
                                if (entry.isIntersecting) {
                                    props.configuration.shiftValue.value = 1650
                                }
                            }

                            s4 -> {
                                if (entry.isIntersecting) {
                                    props.configuration.shiftValue.value = 1750
                                }
                            }

                            s5 -> {
                                if (entry.isIntersecting) {
                                    props.configuration.shiftValue.value = 1790
                                }
                            }

                            s6 -> {
                                if (entry.isIntersecting) {
                                    props.configuration.shiftValue.value = 1815
                                }
                            }

                            else -> {
                                println("    Entry: ${entry.intersectionRatio}")
                            }
                        }
                    }
                }
            },
        )
        observer.observe(s1!!)
        observer.observe(s2!!)
        observer.observe(s3!!)
        observer.observe(s4!!)
        observer.observe(s5!!)
        observer.observe(s6!!)

        cleanup {
            observer.unobserve(s1)
            observer.unobserve(s2)
            observer.unobserve(s3)
            observer.unobserve(s4)
            observer.unobserve(s5)
            observer.unobserve(s6)
            observer.disconnect()
        }
    }

    Parallax {
        Section {
            CardHeader {
                title = ReactNode("The case of Prussia")
            }

            CardContent {
                ref = s1Ref

                Box {
                    Typography {
                        component = ReactHTML.span
                        +"Categorized as following a “coercion-intensive” trajectory by "
                    }
                    Typography {
                        if(props.mode != EventMode.AUTO) sx { textDecoration =  TextDecoration.underline }
                        component = ReactHTML.span
                        onClick = {
                            props.configuration.shiftValue.value = 1700
                        }
                        +"Tilly (1990)"
                    }
                    Typography {
                        component = ReactHTML.span
                        +" . the history of Prussia serves as a clear example of a bellicist state formation process before the French Revolution. Figure 5.5a reveals a striking growth trajectory that was primarily driven by war, as illustrated by the red area from the mid-17th century. Before this process, the Hohenzollern added a few holdings to their territory through marriage (Bendix 1978, 157). During a Swedish occupation in the Thirty Years’ War, they briefly lost control over their core (see Figure 5.5a)."
                    }
                }
            }
        }
    }

    Parallax {
        Section {
            CardHeader {
                title = ReactNode("Thirty Year’s War ")
            }

            CardContent {
                ref = s2Ref

                Box {
                    Typography {
                        component = ReactHTML.span
                        +"In the aftermath of the "
                    }
                    Typography {
                        if(props.mode != EventMode.AUTO) sx { textDecoration =  TextDecoration.underline }
                        component = ReactHTML.span
                        onClick = {
                            props.configuration.shiftValue.value = 1650
                        }
                        +"Thirty Year’s War"
                    }
                    Typography {
                        component = ReactHTML.span
                        +" russia conquered parts of Pomerania (see the red territory in the north), and a decade later, conquest during the Great Northern War allowed Prussia to wrest East Prussia away from Poland (see the red territory in the east)."
                    }
                }
            }

//            Media {
//                 Havariertes Sammelschienentrennermodul der GIS-Anlage nach der Demontage
//                component = "img"
//                height = "300"
//                image = "events/laufenburg/2023-03-08_16-25-45.jpg"
//
//            }
        }
    }

    Parallax {
        Section {
//            title = "Auswirkungen"
            CardHeader {
                sx { textTransform = None.none }
                title = ReactNode("Fredericks")
            }

//            Media {
//            }

            CardContent {
                ref = s3Ref
                Typography {
                    +"Still feeling threatened on various fronts around its core and in need of resources, Frederick I and his successor, Frederick the Great, embarked on further expansionist campaigns (Downing 1992, 92). To widen the resource base, the Prussian monarchs set out to conquer new territories, which aggravated existing rivalries and led to further wars and conquests (see Bendix 1978, 161; Downing 1992,105; Ertman 1997, 256)"
                }
            }
        }
    }

    Parallax {
        Section {
//            title = "Auswirkungen auf das Übertragungsnetz"
//            ref = auswirkungenRef
            CardHeader {
                title = ReactNode("Silesian War")
            }

            CardContent {
                ref = s4Ref
                Typography {
                    +"This self-reinforcing process included the Silesian War with the Habsburgs, which allowed Prussia to gain the rich province of Silesia (see the red area in the south), and the Seven Years’ War."
                }
            }
        }
    }

    Parallax {
        Section {
//            title = "Massnahmen"
            CardHeader {
                title = ReactNode("Massnahmen Juli")
            }

            CardContent {
                ref = s5Ref

                Box {
                    Typography {
                        component = ReactHTML.span
                        +" The latter led to a temporary "
                    }
                    Typography {
                        if(props.mode != EventMode.AUTO) sx { textDecoration =  TextDecoration.underline }
                        component = ReactHTML.span
                        onClick = {
                            props.configuration.shiftValue.value = 1775
                        }
                        +"occupation of Saxony"
                    }
                    Typography {
                        component = ReactHTML.span
                        +"  and forced Prussia to expel rivals from East Prussia and the former Julich-Cleves-Berg province (see the red areas in the east and west respectively)."
                    }
                }
            }
        }
    }

    Parallax {
        Section {
//            title = "Massnahmen"
            CardHeader {
                title = ReactNode("Massnahmen August")
            }

            CardContent {
                ref = s6Ref

                Box {
                    Typography {
                        component = ReactHTML.span
                        +"Overall, war transformed Prussia from a loose set of territories in "
                    }
                    Typography {
                        if(props.mode != EventMode.AUTO) sx { textDecoration =  TextDecoration.underline }
                        component = ReactHTML.span
                        onClick = {
//                            props.configuration.timeTracker.dateInputted("20220812_0000".toDateTime())
//                            props.configuration.timeTracker.timeSliderReleased(-(24 * 60) + ((24 * 60) + 0))
                            props.configuration.shiftValue.value = 1490
                        }
                        +"1490"
                    }
                    Typography {
                        component = ReactHTML.span
                        +" to a much larger and more cohesive unit, a development that would continue in the following century, culminating with the unification of Germany"
                    }
                }
            }
        }
    }
}

