package com.macrofocus.eflux.animation

import com.macrofocus.common.math.PI
import org.locationtech.jts.legacy.Math.cos
import org.locationtech.jts.legacy.Math.pow
import org.locationtech.jts.legacy.Math.sin
import org.locationtech.jts.legacy.Math.sqrt
import kotlin.math.abs
import kotlin.math.asin

/**
 *
 * The Easing class holds a set of general-purpose motion
 * tweening functions by Robert Penner. This class is
 * essentially a port from Penner's ActionScript utility,
 * with a few added tweaks.
 *
 * Examples:<pre>
 * //no tween
 * Easing e1 = Easing.LINEAR;
 *
 * //backOut tween, the overshoot is Easing.Back.DEFAULT_OVERSHOOT
 * Easing e2 = Easing.BACK_OUT;
 *
 * //backOut tween, the overshoot is 1.85f
 * Easing.Back e3 = new Easing.BackOut(1.85f);
</pre> *
 * [Robert Penner's Easing Functions](http://www.robertpenner.com/easing/)
 * @author Robert Penner (functions)
 * @author davedes (java port)
 */
interface Easing {
    /**
     * The basic function for easing.
     * @param t the time (either frames or in seconds/milliseconds)
     * @param b the beginning value
     * @param c the value changed
     * @param d the duration time
     * @return the eased value
     */
    fun ease(t: Double, b: Double, c: Double, d: Double): Double
    /////////// ELASTIC EASING: exponentially decaying sine wave  //////////////
    /**
     * A base class for elastic easings.
     */
    abstract class Elastic constructor(
        /**
         * Sets the amplitude to the given value.
         * @param amplitude the new amplitude
         */
        var amplitude: Double = -1.0,
        /**
         * Sets the period to the given value.
         * @param period the new period
         */
        var period: Double = 0.0
    ) :
        Easing {
        /**
         * Returns the amplitude.
         * @return the amplitude for this easing
         */
        /**
         * Returns the period.
         * @return the period for this easing
         */
        /**
         * Creates a new Elastic easing with the specified settings.
         * @param amplitude the amplitude for the elastic function
         * @param period the period for the elastic function
         */
        /**
         * Creates a new Elastic easing with default settings (-1.0, 0.0).
         */
        init {
            period = period
        }
    }

    /** An Elastic easing used for ElasticIn functions.  */
    class ElasticIn : Elastic {
        constructor(amplitude: Double, period: Double) : super(amplitude, period) {}
        constructor() : super() {}

        override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
            var t = t
            var a = amplitude
            var p = period
            if (t == 0.0) return b
            if (d.let { t /= it; t } == 1.0) return b + c
            if (p == 0.0) p = d * .3
            var s = 0.0
            if (a < abs(c)) {
                a = c
                s = p / 4.0
            } else s = p / (2.0 * PI) * asin((c / a))
            return -(a * pow(2.0, (10 * 1.let { t -= it; t })) * sin(
                (t * d - s) * (2.0 * PI) / p
            )) + b
        }
    }

    /** An Elastic easing used for ElasticOut functions.  */
    class ElasticOut : Elastic {
        constructor(amplitude: Double, period: Double) : super(amplitude, period) {}
        constructor() : super() {}

        override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
            var t = t
            var a = amplitude
            var p = period
            if (t == 0.0) return b
            if (d.let { t /= it; t } == 1.0) return b + c
            if (p == 0.0) p = d * .3
            var s = 0.0
            if (a < abs(c)) {
                a = c
                s = p / 4
            } else s = p / (2.0 * PI) * asin((c / a))
            return a * pow(2.0, (-10.0 * t)) * sin((t * d - s) * (2.0 * PI) / p) + c + b
        }
    }

    /** An Elastic easing used for ElasticInOut functions.  */
    class ElasticInOut : Elastic {
        constructor(amplitude: Double, period: Double) : super(amplitude, period) {}
        constructor() : super() {}

        override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
            var t = t
            var a = amplitude
            var p = period
            if (t == 0.0) return b
            if (d / 2.let { t /= it; t } == 2.0) return b + c
            if (p == 0.0) p = d * (.3f * 1.5f)
            var s = 0.0
            if (a < abs(c)) {
                a = c
                s = p / 4f
            } else s = p / (2 * PI) * asin((c / a))
            return if (t < 1) -.5f * (a * pow(2.0, (10 * 1.let { t -= it; t }))
                 * sin((t * d - s) * (2.0 * PI) / p)) + b else a * pow(
                2.0,
                (-10 * 1.let { t -= it; t })
            ) * sin((t * d - s) * (2.0 * PI) / p) * .5f + c + b
        }
    }
    /////////// BACK EASING: overshooting cubic easing: (s+1)*t^3 - s*t^2  //////////////
    /** A base class for Back easings.  */
    abstract class Back constructor(overshoot: Double = DEFAULT_OVERSHOOT) :
        Easing {
        /**
         * Returns the overshoot for this easing.
         * @return this easing's overshoot
         */
        /**
         * Sets the overshoot to the given value.
         * @param overshoot the new overshoot
         */
        var overshoot: Double
        /**
         * Creates a new Back instance with the specified overshoot.
         * @param overshoot the amount to overshoot by -- higher number
         * means more overshoot and an overshoot of 0 results in
         * cubic easing with no overshoot
         */
        /** Creates a new Back instance with the default overshoot (1.70158).  */
        init {
            this.overshoot = overshoot
        }

        companion object {
            /** The default overshoot is 10% (1.70158).  */
            const val DEFAULT_OVERSHOOT = 1.70158
        }
    }

    /** Back easing in - backtracking slightly, then reversing direction and moving to target.  */
    class BackIn : Back {
        constructor() : super() {}
        constructor(overshoot: Double) : super(overshoot) {}

        override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
            var t = t
            val s = overshoot
            return c * d.let { t /= it; t } * t * ((s + 1) * t - s) + b
        }
    }

    /** Back easing out - moving towards target, overshooting it slightly, then reversing and coming back to target.  */
    class BackOut : Back {
        constructor() : super() {}
        constructor(overshoot: Double) : super(overshoot) {}

        override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
            var t = t
            val s = overshoot
            return c * ((t / d - 1.0.also { t = it }) * t * ((s + 1) * t + s) + 1) + b
        }
    }

    /**
     * Back easing in/out - backtracking slightly, then reversing direction and moving to target,
     * then overshooting target, reversing, and finally coming back to target.
     */
//    class BackInOut : Back {
//        constructor() : super() {}
//        constructor(overshoot: Double) : super(overshoot) {}
//
//        override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
//            var t2 = t
//            var s = overshoot
//            return if (d / 2.let { t2 /= it; t2 } < 1) c / 2 * (t2 * t2 * ((1.525.let { (s *= it)); s } + 1) * t2 - s)) + b else c / 2 * (2.let { t2 -= it; t2 } * t2 * ((1.525.let { (s *= it); s } + 1) * t2 + s) + 2) + b
//        }
//    }

    companion object {
        /**
         * Simple linear tweening - no easing.
         */
        val LINEAR: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                return c * t / d + b
            }
        }
        ///////////// QUADRATIC EASING: t^2 ///////////////////
        /**
         * Quadratic easing in - accelerating from zero velocity.
         */
        val QUAD_IN: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return c * d.let { t /= it; t } * t + b
            }
        }

        /**
         * Quadratic easing out - decelerating to zero velocity.
         */
        val QUAD_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return -c * d.let { t /= it; t } * (t - 2) + b
            }
        }

        /**
         * Quadratic easing in/out - acceleration until halfway, then deceleration
         */
        val QUAD_IN_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return if (d / 2.let { t /= it; t } < 1) c / 2 * t * t + b else -c / 2 * (--t * (t - 2) - 1) + b
            }
        }
        ///////////// CUBIC EASING: t^3 ///////////////////////
        /**
         * Cubic easing in - accelerating from zero velocity.
         */
        val CUBIC_IN: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return c * d.let { t /= it; t } * t * t + b
            }
        }

        /**
         * Cubic easing out - decelerating to zero velocity.
         */
        val CUBIC_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return c * ((t / d - 1.0.also { t = it }) * t * t + 1) + b
            }
        }

        /**
         * Cubic easing in/out - acceleration until halfway, then deceleration.
         */
        val CUBIC_IN_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return if (d / 2.let { t /= it; t } < 1) c / 2 * t * t * t + b else c / 2 * (2.let { t -= it; t } * t * t + 2) + b
            }
        }
        ///////////// QUARTIC EASING: t^4 /////////////////////
        /**
         * Quartic easing in - accelerating from zero velocity.
         */
        val QUARTIC_IN: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return c * d.let { t /= it; t } * t * t * t + b
            }
        }

        /**
         * Quartic easing out - decelerating to zero velocity.
         */
        val QUARTIC_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return -c * ((t / d - 1.0.also { t = it }) * t * t * t - 1) + b
            }
        }

        /**
         * Quartic easing in/out - acceleration until halfway, then deceleration.
         */
        val QUARTIC_IN_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return if (d / 2.let { t /= it; t } < 1) c / 2 * t * t * t * t + b else -c / 2 * (2.let { t -= it; t } * t * t * t - 2) + b
            }
        }
        ///////////// QUINTIC EASING: t^5  ////////////////////
        /**
         * Quintic easing in - accelerating from zero velocity.
         */
        val QUINTIC_IN: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return c * d.let { t /= it; t } * t * t * t * t + b
            }
        }

        /**
         * Quintic easing out - decelerating to zero velocity.
         */
        val QUINTIC_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return c * ((t / d - 1.0.also { t = it }) * t * t * t * t + 1) + b
            }
        }

        /**
         * Quintic easing in/out - acceleration until halfway, then deceleration.
         */
        val QUINTIC_IN_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return if (d / 2.let { t /= it; t } < 1) c / 2 * t * t * t * t * t + b else c / 2 * (2.let { t -= it; t } * t * t * t * t + 2) + b
            }
        }
        ///////////// SINUSOIDAL EASING: sin(t) ///////////////
        /**
         * Sinusoidal easing in - accelerating from zero velocity.
         */
        val SINE_IN: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                return -c * cos(t / d * (PI / 2.0)) + c + b
            }
        }

        /**
         * Sinusoidal easing out - decelerating to zero velocity.
         */
        val SINE_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                return c * sin(t / d * (PI / 2.0)) + b
            }
        }

        /**
         * Sinusoidal easing in/out - accelerating until halfway, then decelerating.
         */
        val SINE_IN_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                return -c / 2 * (cos(PI * t / d) - 1) + b
            }
        }
        ///////////// EXPONENTIAL EASING: 2^t /////////////////
        /**
         * Exponential easing in - accelerating from zero velocity.
         */
        val EXPO_IN: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                return if (t == 0.0) b else c * pow(2.0, (10 * (t / d - 1))) + b
            }
        }

        /**
         * Exponential easing out - decelerating to zero velocity.
         */
        val EXPO_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                return if (t == d) b + c else c * (-pow(2.0, (-10 * t / d)) + 1) + b
            }
        }

        /**
         * Exponential easing in/out - accelerating until halfway, then decelerating.
         */
        val EXPO_IN_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                if (t == 0.0) return b
                if (t == d) return b + c
                return if (d / 2.0.let { t /= it; t } < 1.0) c / 2.0 * pow(2.0, (10 * (t - 1))) + b else c / 2.0 * (-pow(2.0, (-10 * --t)) + 2) + b
            }
        }
        /////////// CIRCULAR EASING: sqrt(1-t^2) //////////////
        /**
         * Circular easing in - accelerating from zero velocity.
         */
        val CIRC_IN: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return -c * (sqrt((1 - d.let { t /= it; t } * t)) - 1) + b
            }
        }

        /**
         * Circular easing out - decelerating to zero velocity.
         */
        val CIRC_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return c * sqrt((1.0 - (t / d - 1.0.also { t = it }) * t)) + b
            }
        }

        /**
         * Circular easing in/out - acceleration until halfway, then deceleration.
         */
        val CIRC_IN_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return if (d / 2.let { t /= it; t } < 1) -c / 2 * (sqrt((1 - t * t))
                     - 1) + b else c / 2 * (sqrt((1 - 2.let { t -= it; t } * t))
                     + 1) + b
            }
        }

        /** An EasingIn instance using the default values.  */
        val ELASTIC_IN: Elastic = ElasticIn()

        /** An ElasticOut instance using the default values.  */
        val ELASTIC_OUT: Elastic = ElasticOut()

        /** An ElasticInOut instance using the default values.  */
        val ELASTIC_IN_OUT: Elastic = ElasticInOut()

        /** An instance of BackIn using the default overshoot.  */
        val BACK_IN: Back = BackIn()

        /** An instance of BackOut using the default overshoot.  */
        val BACK_OUT: Back = BackOut()

        /** An instance of BackInOut using the default overshoot.  */
//        val BACK_IN_OUT: Back = BackInOut()
        /////////// BOUNCE EASING: exponentially decaying parabolic bounce  //////////////
        /** Bounce easing in.  */
        val BOUNCE_IN: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                return c - BOUNCE_OUT.ease(d - t, 0.0, c, d) + b
            }
        }

        /** Bounce easing out.  */
        val BOUNCE_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                var t = t
                return if (d.let { t /= it; t } < 1 / 2.75f) {
                    c * (7.5625f * t * t) + b
                } else if (t < 2 / 2.75f) {
                    c * (7.5625f * (1.5f / 2.75f).let { t -= it; t } * t + .75f) + b
                } else if (t < (2.5f / 2.75f)) {
                    c * (7.5625f * (2.25f / 2.75f).let { t -= it; t } * t + .9375f) + b
                } else {
                    c * (7.5625f * (2.625f / 2.75f).let { t -= it; t } * t + .984375f) + b
                }
            }
        }

        /** Bounce easing in/out.  */
        val BOUNCE_IN_OUT: Easing = object : Easing {
            override fun ease(t: Double, b: Double, c: Double, d: Double): Double {
                return if (t < d / 2) BOUNCE_IN.ease(t * 2, 0.0, c, d) * .5f + b else BOUNCE_OUT.ease(
                    t * 2 - d,
                    0.0,
                    c,
                    d
                ) * .5f + c * .5f + b
            }
        }
    }
}