package com.macrofocus.common.crossplatform

import kotlinx.browser.window

/**
 * A simplified, browser-safe timer class. This class serves the same purpose as java.util.Timer,
 * but is simplified because of the single-threaded environment.
 *
 *
 * To schedule a timer, simply create a subclass of it (overriding [.run]) and call [ ][.schedule] or [.scheduleRepeating].
 *
 *
 * NOTE: If you are using a timer to schedule a UI animation, use [ ] or [DomGlobal.requestAnimationFrame]
 * instead. The browser can optimize your animation for maximum performance.
 */
abstract class Timer {
    private var isRepeating = false
    private var timerId: Int? = null

    /**
     * Returns `true` if the timer is running. Timer is running if and only if it is scheduled
     * but it is not expired or cancelled.
     *
     * @return boolean
     */
    val isRunning: Boolean
        get() = timerId != null

    /** Cancels this timer. If the timer is not running, this is a no-op.  */
    fun cancel() {
        if (!isRunning) {
            return
        }
        if (isRepeating) {
            window.clearInterval(timerId!!)
        } else {
            window.clearTimeout(timerId!!)
        }
        timerId = null
    }

    /** This method will be called when a timer fires. Override it to implement the timer's logic.  */
    abstract fun run()

    /**
     * Schedules a timer to elapse in the future. If the timer is already running then it will be
     * first canceled before re-scheduling.
     *
     * @param delayMillis how long to wait before the timer elapses, in milliseconds
     */
    fun schedule(delayMillis: Int) {
        require(delayMillis >= 0) { "must be non-negative" }
        if (isRunning) {
            cancel()
        }
        isRepeating = false
        timerId = window.setTimeout(createTimeoutCallback(this), delayMillis)
    }

    /**
     * Schedules a timer that elapses repeatedly. If the timer is already running then it will be
     * first canceled before re-scheduling.
     *
     * @param periodMillis how long to wait before the timer elapses, in milliseconds, between each
     * repetition
     */
    fun scheduleRepeating(periodMillis: Int) {
        require(periodMillis > 0) { "must be positive" }
        if (isRunning) {
            cancel()
        }
        isRepeating = true
        timerId = window.setInterval(createIntervalCallback(this), periodMillis)
    }

    /*
     * Called by native code when this timer fires.
     *
     * Only call run() if cancelCounter has not changed since the timer was scheduled.
     */
    fun fire() {
        if (!isRepeating) {
            timerId = null
        }

        // Run the timer's code.
        run()
    }

    private fun createTimeoutCallback(timer: Timer): dynamic {
        return { timer.fire() }
    }

    private fun createIntervalCallback(timer: Timer): dynamic {
        return { timer.fire() }
    }
}