package com.macrofocus.nastac.theme

import js.core.jso
import mui.material.PaletteMode.Companion.dark
import mui.material.PaletteMode.Companion.light
import mui.material.styles.createTheme

object Themes {
    val Light = createTheme(
        jso {
            palette = jso {
                mode = light

                primary = jso {
                    // Header background color
//                    main = "#e65100"
                    main = "#1976d2" // Default
                }
                secondary = jso {
                    main = "#808080"
                }
                background = jso {
                    paper = "#ffffff"
                    // Content background color
                    default = "#ffffff"
//                    default = "#fafafa"
                }
            }
        }
    )

    val Dark = createTheme(
        jso {
            palette = jso {
                mode = dark

                primary = jso {
//                    main = "#ffcc80"
                    main = "#90caf9" // Default
                }
                secondary = jso {
                    main = "#ffffff"
                }
                background = jso {
                    // General background color
                    paper = "#000000" //"#182f3c" // "#303030"
                    // Content background color
                    default = "#323232" // "#0e2232" For e
                }
            }
        }
    )
}