package com.macrofocus.nastac.events

import mui.material.Box
import mui.system.sx
import react.FC
import react.PropsWithChildren

val Parallax = FC<PropsWithChildren> { props ->
    Box {
        sx {
//            backgroundImage = url("events/laufenburg/2023-03-08_16-25-45.jpg")
//            position = Position.relative
//            minHeight = 100.pct
//            backgroundAttachment = BackgroundAttachment.fixed
//            backgroundPosition = GeometryPosition.center
//            backgroundRepeat = BackgroundRepeat.noRepeat
//            backgroundSize = BackgroundSize.cover
        }

        +props.children
    }
}
