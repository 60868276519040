package com.macrofocus.nastac.components

import com.macrofocus.nastac.NASTAC
import react.FC
import react.PropsWithChildren
import react.createRequiredContext

val NASTACContext = createRequiredContext<NASTAC>()

external interface NASTACModuleProps : PropsWithChildren {
    var nastac: NASTAC
}

val NASTACModule = FC<NASTACModuleProps> { props ->
//    val diviso = useDiviso()

    NASTACContext(props.nastac) {
        +props.children
    }
}