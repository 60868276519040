package com.macrofocus.nastac.ui.react

import com.macrofocus.common.properties.MutableProperty
import com.macrofocus.common.properties.PropertyEvent
import com.macrofocus.common.properties.PropertyListener
import react.useEffect
import react.useState

fun <T> MutableProperty<T>.toState() : Pair<T, SetState<T>> {
    val pair = useState {this.value}
    val (getValue, setStateValue) = pair

    val setPropertyValue : SetState<T> = { this.value = it }

    val property = this

    useEffect {
        val listener = object : PropertyListener<T> {
            override fun propertyChanged(event: PropertyEvent<T>) {
//                println("Property value changed from " + event.oldValue + " to " + event.newValue)
                setStateValue(event.newValue)
            }
        }
        property.addPropertyListener(listener)

        cleanup { property.removePropertyListener(listener) }
    }
    return Pair(getValue, setPropertyValue)
}
