package com.macrofocus.nastac.components

import com.macrofocus.nastac.Area
import com.macrofocus.nastac.theme.ThemeContext
import csstype.*
import js.core.jso
import mui.material.Drawer
import mui.material.DrawerAnchor.Companion.left
import mui.material.DrawerVariant.Companion.permanent
import mui.material.PaletteMode
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.nav
import react.useContext
import react.useRequiredContext
import web.cssom.*

val Sidebar = FC<Props> {
    var theme by useRequiredContext(ThemeContext)

//    val lastPathname = useLocation().pathname.substringAfterLast("/")

    Box {
        component = nav
        sx { gridArea = Area.Sidebar }

        Drawer {
            variant = permanent
            anchor = left

            sx = jso {
//                flexGrow = number(1.0)
//                flexShrink = number(1.0)
//                height = 100.pct - Sizes.Header.height
//                backdropFilter

                position = Position.absolute
                left = 16.px
                top = 16.px
            }

            PaperProps = jso {
                sx = jso {
//                    boxSizing = BoxSizing.borderBox
//                    position = Position.sticky

                    // Prevent the sidebar from extending to the bottom
                    height = Globals.unset

                    backgroundColor = if (theme.palette.mode == PaletteMode.light) rgb(255, 255, 255, 0.5) else rgb(0, 0, 0, 0.2)
                    backgroundImage = if (theme.palette.mode == PaletteMode.light)
                        linearGradient(
                            stop(rgb(0, 0, 0, 0.08), 0.pct),
                            stop(rgb(0, 0, 0, 0.01), 100.pct)
                        )
                    else
                        linearGradient(
                            stop(rgb(255, 255, 255, 0.08), 0.pct),
                            stop(rgb(255, 255, 255, 0.01), 100.pct)
                        )
//                    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
                    boxShadow = BoxShadow(0.px, 3.px, 5.px, -1.px, rgb(0, 0, 0, 0.2))
                    backdropFilter = blur(4.px)

                    borderRight = Globals.unset
                    position = Position.absolute
                    left = Globals.unset
                    top = Globals.unset

                    overflowY = Overflow.visible
                }
            }

            Box {
//                Toolbar()

                SidebarContent {}
            }
        }
    }
}
