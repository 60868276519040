package com.macrofocus.eflux.animation

import com.macrofocus.common.properties.MutableProperty
import kotlin.math.max
import kotlin.math.min

class PropertyAnimation(private val property: MutableProperty<Double>, private val endValue: Double, override val duration: Long, private val easing: Easing = Easing.LINEAR) : Animation {
    var startValue : Double? = null

    override fun start() {
        startValue = property.value
    }

    override fun tick(time: Long) {
        val normalizedTime = max(0.0, min(1.0, time.toDouble() / duration))

        startValue?.let { startValue ->
            val diff = endValue - startValue
//            property.value = startValue + (diff * normalizedTime)
            val eased = easing.ease(normalizedTime, startValue, diff, 1.0).toDouble()
            val min = min(startValue, endValue)
            val max = max(startValue, endValue)
            val capped = max(min, min(max, eased))

            property.value = capped
        }
    }
}