package com.macrofocus.nastac.components

import com.macrofocus.nastac.DataLoader
import com.macrofocus.nastac.theme.ThemeContext
import com.macrofocus.nastac.ui.PaletteType
import com.macrofocus.nastac.ui.react.toState
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.async
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import org.jetbrains.letsPlot.Stat
import org.jetbrains.letsPlot.frontend.JsFrontendUtil
import org.jetbrains.letsPlot.geom.geomBar
import org.jetbrains.letsPlot.geom.geomDensity
import org.jetbrains.letsPlot.ggsize
import org.jetbrains.letsPlot.letsPlot
import org.jetbrains.letsPlot.pos.positionStack
import org.jetbrains.letsPlot.stat.statBin
import org.jetbrains.letsPlot.stat.statBin2D
import org.jetbrains.letsPlot.themes.*
import org.mkui.canvas.ResizeObserver
import org.molap.dataframe.DataFrame
import org.molap.dataframe.JsonDataFrame
import org.molap.geojson.GeoJSONDataFrame
import react.*
import react.dom.html.ReactHTML.div
import web.cssom.Position
import web.cssom.px
import web.dom.Node
import web.html.HTMLDivElement
import kotlin.math.PI
import kotlin.math.cos
import kotlin.math.ln
import kotlin.math.sqrt
import kotlin.random.Random

fun resize(div: HTMLDivElement, setWidth: StateSetter<Int>, setHeight: StateSetter<Int>) {
    val width = div.clientWidth // Todo: offsetWidth
    val height = div.clientHeight // Todo: offsetHeight
    setWidth(width)
    setHeight(height)
}

external interface TimeSeriesProps : Props {
    var dataLoader: DataLoader
}

val TimeSeries = FC<TimeSeriesProps> { props ->
    var theme by useContext(ThemeContext)!!
    val paletteType = if(theme.asDynamic().palette.mode == "dark") PaletteType.dark else PaletteType.light

    val (width, setWidth) = useState { 1600 }
    val (height, setHeight) = useState { 1200 }

    println("Creating resizable SVG")

    val (country, _) = props.dataLoader.countrySelection.toState()

    var histParentRef = useRef<HTMLDivElement>(null)

    useEffectOnce {
        val div = histParentRef.current!!
        val resizeObserver = ResizeObserver { _, _ -> resize(div, setWidth, setHeight) }
        resizeObserver.observe(div)

        cleanup {
            resizeObserver.unobserve(div)
            resizeObserver.disconnect()
        }
    }

    val type = "Area"
    val (populationData, setPopulationData) = useState(null as JsonDataFrame?)
    useEffect(type, country) {
        if (country != null) {
            GlobalScope.launch {
                val dataFrame = window.fetch("/ts/$type/$country").then {
                    //                async { JsonDataFrame(it.json().await().unsafeCast<Array<Any?>>()) }
                    async { JsonDataFrame(it.text().await()) }
                }.await().await()
                println("Setting data frame $dataFrame")
                setPopulationData(dataFrame)
            }
        } else {
            setPopulationData(null)
        }
    }

    useEffect(paletteType, populationData, width, height) {
        if (populationData != null) {
            val n = populationData.rowCount
            println("Got $n")
            val data = mapOf<String, Any>(
                "year" to List(n) { populationData.getValueAt(it, "Year") },
                "all" to List(n) { populationData.getValueAt(it, "All") },
                "peace" to List(n) { populationData.getValueAt(it, "All") as Double? - populationData.getValueAt(it, "Nullius") as Double? },
                "war" to List(n) { populationData.getValueAt(it, "All") as Double? - populationData.getValueAt(it, "Nullius") as Double? - populationData.getValueAt(it, "Peace") as Double? },
                "core" to List(n) { populationData.getValueAt(it, "All") as Double? - populationData.getValueAt(it, "Nullius") as Double? - populationData.getValueAt(it, "Peace") as Double? - populationData.getValueAt(it, "War") as Double? }
            )

            val p = letsPlot(data) +
                    // Terra Nullius
                    geomBar(
                        position = positionStack(),
                        stat = Stat.identity,
                        fill = "blue",
                    ) {
                        x = "year"
                        y = "all"
                    } +
                    geomBar(
                        position = positionStack(),
                        stat = Stat.identity,
                        fill = "rgb(127, 255, 0)" // chartreuse3
                    ) {
                        x = "year"
                        y = "peace"
                    } +
                    geomBar(
                        position = positionStack(),
                        stat = Stat.identity,
                        fill = "red"
                    ) {
                        x = "year"
                        y = "war"
                    } +
                    geomBar(
                        position = positionStack(),
                        stat = Stat.identity,
                        fill = "rgb(238, 213, 183)" // bisque2
                    ) {
                        x = "year"
                        y = "core"
                    } +
                    (if(paletteType == PaletteType.dark) flavorSolarizedDark() else flavorHighContrastLight())  +
                    ggsize(width, height)

            val plotDiv: org.w3c.dom.HTMLDivElement = JsFrontendUtil.createPlotDiv(p)

            val current: HTMLDivElement = histParentRef.current!!
            var child = current.lastElementChild;
            while (child != null) {
                current.removeChild(child)
                child = current.lastElementChild
            }
            //        current.remove()
            current.appendChild(plotDiv as Node)
        } else {
            val current: HTMLDivElement = histParentRef.current!!
            var child = current.lastElementChild;
            while (child != null) {
                current.removeChild(child)
                child = current.lastElementChild
            }
        }
    }

    div {
        css {
            position = Position.absolute
            left = 0.px
            right = 0.px
            top = 0.px
            bottom = 0.px
        }

        ref = histParentRef
    }
}

internal operator fun Double?.minus(other: Double?) = if (this != null && other != null) this - other else this
