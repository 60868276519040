package com.macrofocus.common.json

/**
 * Client-side implementation of JsonArray.
 */
actual external interface JsonArray : JsonValue {
    /**
     * Return the ith element of the array.
     */
//    actual operator fun <T : JsonValue?> get(index: Int): T

    /**
     * Return the ith element of the array (uncoerced) as a JsonArray. If the type is not an array,
     * this can result in runtime errors.
     */
//    actual fun getArray(index: Int): JsonArray?

    /**
     * Return the ith element of the array (uncoerced) as a boolean. If the type is not a boolean,
     * this can result in runtime errors.
     */
//    actual fun getBoolean(index: Int): Boolean

    /**
     * Return the ith element of the array (uncoerced) as a number. If the type is not a number, this
     * can result in runtime errors.
     */
//    actual fun getNumber(index: Int): Double

    /**
     * Return the ith element of the array (uncoerced) as a JsonObject If the type is not an object,,
     * this can result in runtime errors.
     */
//    actual fun getObject(index: Int): JsonObject?

    /**
     * Return the ith element of the array (uncoerced) as a String. If the type is not a String, this
     * can result in runtime errors.
     */
//    actual fun getString(index: Int): String?

    /**
     * Length of the array.
     */
//    actual fun length(): Int

    /**
     * Remove an element of the array at a particular index.
     */
//    actual fun remove(index: Int)

    /**
     * Set the value at index to be a given value.
     */
//    actual operator fun set(index: Int, value: JsonValue?)

    /**
     * Set the value at index to be a String value.
     */
//    actual operator fun set(index: Int, string: String)

    /**
     * Set the value at index to be a number value.
     */
//    actual operator fun set(index: Int, number: Double)

    /**
     * Set the value at index to be a boolean value.
     */
//    actual operator fun set(index: Int, bool: Boolean)
}

actual operator fun <T : JsonValue?> JsonArray.get(index: Int): T {
//    return js("var value = this[index]; value == null ? value : Object(value)")
    return this.unsafeCast<dynamic>()[index]
}

actual fun JsonArray.getArray(index: Int): JsonArray? {
//    return get(index) as JsonArray?
    return this.unsafeCast<dynamic>()[index]
}

actual fun JsonArray.getBoolean(index: Int): Boolean {
//    return (get(index) as JsonBoolean).boolean
    return this.unsafeCast<dynamic>()[index]
}

actual fun JsonArray.getNumber(index: Int): Double {
//    return (get(index) as JsonNumber).number
    return this.unsafeCast<dynamic>()[index]
}

actual fun JsonArray.getObject(index: Int): JsonObject? {
//    return get(index) as JsonObject?
    return this.unsafeCast<dynamic>()[index]
}

actual fun JsonArray.getString(index: Int): String? {
//    return (get(index) as JsonString).string
//    val json : dynamic = this
//    return json[index]
    return this.unsafeCast<dynamic>()[index]
}

actual fun JsonArray.length(): Int {
    val json : dynamic = this
    return json.length
//    return js("this.length;")
}

actual fun JsonArray.remove(index: Int) {
    js("this.removeByIndex(index)")
}

actual operator fun JsonArray.set(index: Int, value: JsonValue?) {
//    js("this[index] = value;")
    this.unsafeCast<dynamic>()[index] = value
}

actual operator fun JsonArray.set(index: Int, string: String) {
    set(index, JsonFactory.create(string))
}

actual operator fun JsonArray.set(index: Int, number: Double) {
    set(index, JsonFactory.create(number))
}

actual operator fun JsonArray.set(index: Int, bool: Boolean) {
    set(index, JsonFactory.create(bool))
}

//    companion object {
//        fun create(): JsonArray {
//            return js("new Array;")
//        }
//    }
