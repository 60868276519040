package com.macrofocus.nastac.components

import com.macrofocus.common.properties.MutableProperty
import com.macrofocus.nastac.Sizes
import com.macrofocus.nastac.ViewStyle
import emotion.react.css
import i18next.invoke
import i18next.useTranslation
import mui.icons.material.AirTwoTone
import mui.icons.material.ElectricMeterTwoTone
import mui.icons.material.JoinFullTwoTone
import mui.icons.material.TrainTwoTone
import mui.material.ListItemButton
import mui.material.ListItemIcon
import mui.material.ListItemText
import mui.system.sx
import org.mkui.react.toState
import react.FC
import react.PropsWithChildren
import react.ReactNode
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.router.dom.NavLink
import web.cssom.Color
import web.cssom.None
import web.cssom.WhiteSpace
import web.cssom.px
import web.html.HTMLElement

external interface ViewStyleLinksProps : PropsWithChildren {
    var viewStyle: MutableProperty<ViewStyle>
}

val ViewStyleLinks = FC<ViewStyleLinksProps> { props ->
    val (t, _) = useTranslation()

    val (viewStyle, setViewStyle) = props.viewStyle.toState()

    mui.material.List {
        dense = true
        sx {
            padding = 0.px
//            width = Sizes.Fabbar.Width
        }

        div {
//            to = "flow"

            css {
                textDecoration = None.none
                color = Color.currentcolor
            }

            ListItemButton {
//                                selected = lastPathname == key
                selected = viewStyle == ViewStyle.TILLY

                onClick = { event: MouseEvent<HTMLElement, *> ->
                    setViewStyle(ViewStyle.TILLY)
                }

                ListItemIcon {
                    AirTwoTone {
                    }
                }
                ListItemText {
                    sx {
                        whiteSpace = WhiteSpace.nowrap
                    }
                    primary = ReactNode(ViewStyle.TILLY.toString())
                }
            }
        }

        div {
//            to = "voltage"

            css {
                textDecoration = None.none
                color = Color.currentcolor
            }

            ListItemButton {
//                                selected = lastPathname == key
                selected = viewStyle == ViewStyle.SIZE

                onClick = { _: MouseEvent<HTMLElement, *> ->
                    setViewStyle(ViewStyle.SIZE)
                }

                ListItemIcon {
                    ElectricMeterTwoTone {
                    }
                }
                ListItemText {
                    sx {
                        whiteSpace = WhiteSpace.nowrap
                    }
                    primary = ReactNode(ViewStyle.SIZE.toString())
                }
            }
        }

        div {
//            to = "combined"

            css {
                textDecoration = None.none
                color = Color.currentcolor
            }

            ListItemButton {
//                                selected = lastPathname == key
                selected = viewStyle == ViewStyle.POPULATION

                onClick = { event: MouseEvent<HTMLElement, *> ->
                    setViewStyle(ViewStyle.POPULATION)
                }

                ListItemIcon {
                    JoinFullTwoTone {
                    }
                }
                ListItemText {
                    sx {
                        whiteSpace = WhiteSpace.nowrap
                    }
                    primary = ReactNode(ViewStyle.POPULATION.toString())
                }
            }
        }

        div {
//            to = "combined"

            css {
                textDecoration = None.none
                color = Color.currentcolor
            }

            ListItemButton {
//                                selected = lastPathname == key
                selected = viewStyle == ViewStyle.RSHAPES

                onClick = { event: MouseEvent<HTMLElement, *> ->
                    setViewStyle(ViewStyle.RSHAPES)
                }

                ListItemIcon {
                    TrainTwoTone {
                    }
                }
                ListItemText {
                    sx {
                        whiteSpace = WhiteSpace.nowrap
                    }
                    primary = ReactNode(ViewStyle.RSHAPES.toString())
                }
            }
        }
    }
}