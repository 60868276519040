package com.macrofocus.nastac.events

import com.macrofocus.eflux.animation.Animation
import com.macrofocus.eflux.animation.timeline
import com.macrofocus.nastac.DataLoader
import react.ReactElement
import react.create

enum class Events : Event {
    PRUSSIA {
        override val title: String = "The case of Prussia"

        override fun reactElement(
            props: EventDialogProps,
            mode: EventMode
        ): ReactElement<*> {
            return Prussia.create() {
                this.configuration = props.configuration
                this.mode = mode
            }
        }

        override fun animation(dataLoader: DataLoader): Animation? {
            if(anim == null && dataLoader != null) {
                val threeDPitch = dataLoader.threeDPitch
                val threeDZoom = dataLoader.threeDZoom
                val threeDLatitude = dataLoader.threeDLatitude
                val threeDLongitude = dataLoader.threeDLongitude

                anim = timeline {
                    val duration: Long = 1000
                    // Königsberg 54.7104, 20.4522
                    parallel {
                        property(threeDZoom, 8.0, duration)
                        property(threeDLatitude, 54.7104, duration)
                        property(threeDLongitude, 20.4522, duration)
                    }

//                    parallel {
//                        property(threeDZoom, 5.0, duration)
//                        property(threeDLatitude, (51.507222 + 48.2) / 2, duration)
//                        property(threeDLongitude, (-0.1275 + 16.366667) / 2, duration)
//                    }
                }
                anim?.start()
            }
            return anim
        }

        private var anim: Animation? = null
    },
    FRANCE {
        override val title: String = "The case of France"

        override fun reactElement(
            props: EventDialogProps,
            mode: EventMode
        ): ReactElement<*> {
            return Prussia.create() {
                this.configuration = props.configuration
                this.mode = mode
            }
        }

        override fun animation(dataLoader: DataLoader): Animation? {
            if(anim == null && dataLoader != null) {
                val threeDPitch = dataLoader.threeDPitch
                val threeDZoom = dataLoader.threeDZoom
                val threeDLatitude = dataLoader.threeDLatitude
                val threeDLongitude = dataLoader.threeDLongitude

                anim = timeline {
                    val duration: Long = 1000
                    // Paris 48.8566, 2.3522
                    parallel {
                        property(threeDZoom, 8.0, duration)
                        property(threeDLatitude, 48.8566, duration)
                        property(threeDLongitude, 2.3522, duration)
                    }

//                    parallel {
//                        property(threeDZoom, 5.0, duration)
//                        property(threeDLatitude, (51.507222 + 48.2) / 2, duration)
//                        property(threeDLongitude, (-0.1275 + 16.366667) / 2, duration)
//                    }
                }
            }
            return anim
        }

        private var anim: Animation? = null
    },
    HABSBURG {
        override val title: String = "The case of the Habsburg Empire"

        override fun reactElement(
            props: EventDialogProps,
            mode: EventMode
        ): ReactElement<*> {
            return Prussia.create() {
                this.configuration = props.configuration
                this.mode = mode
            }
        }

        override fun animation(dataLoader: DataLoader): Animation? {
            if(anim == null && dataLoader != null) {
                val threeDPitch = dataLoader.threeDPitch
                val threeDZoom = dataLoader.threeDZoom
                val threeDLatitude = dataLoader.threeDLatitude
                val threeDLongitude = dataLoader.threeDLongitude

                anim = timeline {
                    val duration: Long = 1000
                    // Vienna 48.2, 16.366667
                    parallel {
                        property(threeDZoom, 8.0, duration)
                        property(threeDLatitude, 48.2, duration)
                        property(threeDLongitude, 16.366667, duration)
                    }

//                    parallel {
//                        property(threeDZoom, 5.0, duration)
//                        property(threeDLatitude, (51.507222 + 48.2) / 2, duration)
//                        property(threeDLongitude, (-0.1275 + 16.366667) / 2, duration)
//                    }
                }
            }
            return anim
        }

        private var anim: Animation? = null
    }
    ;
}