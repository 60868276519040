//@file:JsModule("@luma.gl/constants")

package deckgl


//external enum class GL {
//    SRC_ALPHA,
//    ONE,
//    ONE_MINUS_DST_ALPHA,
//    FUNC_ADD
//}

class GL {
    companion object {
        val SRC_ALPHA = 0x0302
        val ONE = 1
        val ONE_MINUS_DST_ALPHA = 0x0305
        val FUNC_ADD = 0x8006
    }
}
