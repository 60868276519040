package com.macrofocus.nastac.data

object GeoEPRAgg {
    val ethnicNames = setOf(
        "EthnicName",
        "Abkhaz",
        "Abkhazians",
        "Acehnese",
        "Adyghe",
        "Afar",
        "African Americans",
        "Afrikaners",
        "Afro-Bahamians (Black)",
        "Afro-Costa Ricans",
        "Afro-Ecuadorians",
        "Afro-Guyanese",
        "Afrobrazilians",
        "Afrocolumbians",
        "Afromexicans",
        "Afronicaraguans",
        "Afropanamanians",
        "Afroperuvians",
        "Afrovenezuelans",
        "Aimaq",
        "Ainu",
        "Alawi",
        "Albanians",
        "Altai",
        "Amboinese",
        "American Indians",
        "Americo-Liberians",
        "Amhara",
        "Annobon Islanders",
        "Anyuak",
        "Arab Muslims",
        "Armenians",
        "Asante (Akan)",
        "Ashkenazim (Jewish)",
        "Assamese (non-SC/ST/OBCs)",
        "Avars",
        "Aymara",
        "Azande-Mangbetu cluster",
        "Azeri",
        "Baganda",
        "Bai",
        "Bakongo",
        "Balanta",
        "Balinese",
        "Balkars",
        "Baluchis",
        "Bamar (Barman)",
        "Bamileke",
        "Barbadians",
        "Bari",
        "Bashkirs",
        "Basoga",
        "Basques",
        "Bassa/Duala",
        "Baster",
        "Basubia",
        "Bataks",
        "Batéké",
        "Baule",
        "Baya",
        "Beja",
        "Bemba speakers",
        "Bembe",
        "Bengali",
        "Bengali Muslims",
        "Beni-Shugal-Gumez",
        "Berbers",
        "Beti (and related peoples)",
        "Birwa",
        "Blacks",
        "Blacks (Mande, Peul, Voltaic etc.)",
        "Blang",
        "Bodo",
        "Bosniaks/Muslims",
        "Bouyei",
        "Bubi",
        "Buddhist Arakanese",
        "Bulgarians",
        "Bumiputera (other)",
        "Buryats",
        "Byelorussians",
        "Cabindan Mayombe",
        "Cape Verdeans",
        "Catalans",
        "Central (Chewa)",
        "Chechens",
        "Cherkess",
        "Chinese (Han)",
        "Choco (Embera-Wounan)",
        "Christian Eritreans",
        "Christian lowlanders",
        "Christians",
        "Chukchi",
        "Chuvashes",
        "Coloreds",
        "Coptic Christians",
        "Corsicans",
        "Côtiers",
        "Creoles",
        "Crimean Tatars",
        "Croats",
        "Czechs",
        "Dai",
        "Damara",
        "Danes",
        "Dargins",
        "Daur",
        "Dayak",
        "Didinga",
        "Dinka",
        "Diola",
        "Divehis",
        "Djerma-Songhai",
        "Dominicans",
        "Dong",
        "Dongxiang",
        "Druze",
        "Dutch",
        "East Indians",
        "East Timorese",
        "English",
        "English Speakers",
        "Eshira/Bapounou",
        "Estonians",
        "Ewe",
        "Fang",
        "Far North-West Nilers (Madi, Lugbara, Alur)",
        "Fernandinos",
        "Fijians",
        "Finns",
        "French",
        "French speakers",
        "Friulians",
        "Fulani (and other northern Muslim peoples)",
        "Fur",
        "Ga-Adangbe",
        "Gagauz",
        "Galicians",
        "Garifuna",
        "Gelao",
        "Georgians",
        "Germans",
        "Gia Rai",
        "Gorani",
        "Gorontalos",
        "Goula",
        "Greeks",
        "Guaraní­ and other eastern indigenous groups",
        "Gujarati (non-SC/ST)",
        "Gur",
        "Hadjerai",
        "Haitians",
        "Hani",
        "Harari",
        "Haratins (Black Moors)",
        "Hausa-Fulani and Muslim Middle Belt",
        "Hazara",
        "Herero, Mbanderu",
        "Highlanders",
        "Himba",
        "Hindi-speaking Hindus",
        "Hui (proper)",
        "Hungarians",
        "Hutu",
        "Icelanders",
        "Igbo",
        "Ijaw",
        "Indians",
        "Indigenous",
        "Indigenous lowland peoples (Shuar, Achuar etc.)",
        "Indigenous peoples",
        "Indigenous Peoples (Arawaks and Caribs)",
        "Indigenous peoples of the Amazon",
        "Indigenous peoples of the Andes",
        "Indigenous Tripuri",
        "Indigenous/Aboriginal Taiwanese",
        "Indo-Guyanese",
        "Ingush",
        "Irish",
        "Italians",
        "Jamaicans",
        "Japanese",
        "Javanese",
        "Kabardins",
        "KabrÃ© (and related groups)",
        "Kachins",
        "Kadazans",
        "Kakwa-Nubian",
        "Kalanga",
        "Kalenjin-Masai-Turkana-Samburu",
        "Kalmyks",
        "Kamba",
        "Kannada (non-SC/ST/OBCs)",
        "Kanouri",
        "Kaonde (NW Province)",
        "Karachai",
        "Karakalpak",
        "Karelians",
        "Karenni (Red Karens)",
        "Kashmiri Muslims",
        "Kavango",
        "Kayin (Karens)",
        "Kazakhs",
        "Kgalagadi",
        "Khakass",
        "Khmer",
        "Khmer Loeu (various indigenous minorities)",
        "Khmou",
        "Kikuyu-Meru-Emb",
        "Kinh (Vietnamese)",
        "Kisii",
        "Komi",
        "Komi-Permyaks",
        "Kono",
        "Koreans",
        "Kouyou",
        "Kru",
        "Kumyks",
        "Kuna",
        "Kunama",
        "Kurds",
        "Kyrgyz",
        "Ladinos",
        "Lahu",
        "Laks",
        "Langi/Acholi",
        "Lao (incl. Phuan)",
        "Lao Thoeng (excl. Khmou)",
        "Lari",
        "Latoka",
        "Latvians",
        "Lezgins",
        "Li",
        "Limba",
        "Lisu",
        "Lithuanians",
        "Lozi (Barotse)",
        "Luba Kasai",
        "Luba Shaba",
        "Luhya",
        "Lulua",
        "Lunda-Yeke",
        "Luvale (NW Province)",
        "Luxembourgers",
        "Macedonians",
        "Madhesi",
        "Madura",
        "Mafwe",
        "Mainland Muslims",
        "Makassarese and Bugis",
        "Malays",
        "Maltese",
        "Malyalam (non-SC/ST)",
        "Manchu",
        "Manipuri",
        "Manjaco",
        "Mano",
        "Manyika (Shona sub-group)",
        "Maonan",
        "Mapuche",
        "Marathi (non-SC/ST/OBCs)",
        "Mari",
        "Maroons",
        "Masalit",
        "Maya",
        "Mbandja",
        "Mbochi (proper)",
        "Mbukushu",
        "Mbundu-Mestico",
        "Mende",
        "Mestizos",
        "Miao",
        "Mijikenda",
        "Minahasa",
        "Minangkabaus",
        "Miskitos",
        "Moldovans",
        "Mongo",
        "Mongolians",
        "Mons",
        "Montenegrins",
        "Moors (Muslims)",
        "Mordva",
        "Moro",
        "Mulam",
        "Mundari",
        "Muong",
        "Murle",
        "Muslim Eritreans",
        "Mwali Comorans",
        "Naga",
        "Nama",
        "Naxi",
        "Ndebele-Kalanga-(Tonga)",
        "Ndowe",
        "New Zealanders",
        "Newars",
        "Ngazidja Comorans",
        "Ngbaka",
        "Ngbandi",
        "Ngobe-Bugle",
        "Nkomi",
        "Nogai",
        "Non-Bumiputera (Indigenous)",
        "Northern (Bariba, Peul, Ottamari, Yoa-Lokpa, Dendi, Gourmanchéma)",
        "Northern Groups (Mole-Dagbani, Gurma, Grusi)",
        "Northerners (Tumbuka, Tonga, Ngonde)",
        "Northwestern Anglophones (Grassfielders)",
        "Norwegians",
        "Nuba",
        "Nuer",
        "Nuristani",
        "Nzwani Comorans",
        "Ogoni",
        "Okinawans",
        "Orang Asli",
        "Oriya (non-SC/ST)",
        "Oroma",
        "Orungu",
        "Ossetes",
        "Other Akans",
        "Other Backward Classes/Castes",
        "Other indigenous groups",
        "Other Kivu groups",
        "Other Muslims",
        "Other Northern Groups",
        "Other Southern groups",
        "Other Southern Nations",
        "Others Mainland (Christians and traditional religions)",
        "Ovimbundu-Ovambo",
        "Palestinian Arabs",
        "Pamiri Tajiks",
        "Papel",
        "Papua New Guineans",
        "Pashtuns",
        "Pedi (North Sotho)",
        "Persians",
        "Peul",
        "Poles",
        "Portuguese",
        "Protestants In N. Ireland",
        "Punjabi",
        "Qiang",
        "Rashaida",
        "Romanians",
        "Russians",
        "Rusyns",
        "Saho",
        "Sahrawis",
        "Salar",
        "San",
        "Sara",
        "Sardinians",
        "Scheduled Tribes",
        "Scots",
        "Serbs",
        "Serer",
        "Shan",
        "Sharchops",
        "She",
        "Shi'a Arabs",
        "Shilluk",
        "Shirazi (Zanzibar Africans)",
        "Shui",
        "Sindhi",
        "Sinhalese",
        "Slovaks",
        "Slovenes",
        "Solomon Islanders",
        "Somali",
        "South-Westerners (Ankole, Banyoro, Toro)",
        "South/Central (Fon)",
        "Southern Mande",
        "Southerners (Lomwe, Mang'anja, Nyanja, Yao)",
        "Southwestern (Adja)",
        "Southwestern Anglophones (Bakweri etc.)",
        "Spanish",
        "Sumus",
        "Sundanese",
        "Susu",
        "Swazi",
        "Swedes",
        "Tabasarans",
        "Talysh",
        "Tamil (non-SC/ST)",
        "Tatars",
        "Temne",
        "Ternate",
        "Teso",
        "Tetela-Kusu",
        "Thai",
        "Tibetans",
        "Tigry",
        "Tiv",
        "Toposa",
        "Toubou",
        "Tsonga-Chopi",
        "Tswana",
        "Tswapong",
        "Tujia",
        "Turkish",
        "Turkmen",
        "Tutsi",
        "Tuvinians",
        "Udmurt",
        "Ukrainians",
        "Uyghur",
        "Uzbeks",
        "Venda",
        "Vili",
        "Wa",
        "Welsh",
        "White Moors (Beydan)",
        "Whites",
        "Whites/mestizos",
        "Wolof",
        "Xhosa",
        "Xibe",
        "Xinca",
        "Yakoma",
        "Yakuts",
        "Yao",
        "Yeyi",
        "Yi",
        "Yoruba",
        "Zaghawa",
        "Zanzibar Arabs",
        "Zhuang",
        "Zomis (Chins)",
        "Zulu",
    )
}