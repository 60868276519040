package com.macrofocus.eflux.animation

import com.macrofocus.common.crossplatform.Timer
import com.macrofocus.eflux.ui.react.useInterval
import korlibs.time.DateTime
import react.useState

fun timeline(init: Timeline.() -> Unit) : Timeline {
    val timeline = Timeline()
    timeline.init()
    return timeline
}

class Timeline : Animation {
    val animations = ArrayList<Animation>()

    val react = false

    var started = false

    fun parallel(init: ParallelAnimation.() -> Unit) : ParallelAnimation {
        val parallelAnimation = ParallelAnimation()
        parallelAnimation.init()
        addAnimation(parallelAnimation)
        return parallelAnimation
    }

    fun addAnimation(animation: Animation) {
        animations.add(animation)
    }

    override val duration: Long
        get() = animations.sumOf { it.duration }

    override fun tick(time: Long) {
        animations.first().tick(time)
    }

    override fun start() {
        val animationsIterator = animations.iterator()
        var animation = animationsIterator.next()
        var startTime = DateTime.now()
        animation.start()

        if(react) {
            val (delay, setDelay) = useState<Int?>(1000 / 60)

            useInterval({
                val duration = animation.duration
                val elapsedTime = (DateTime.now() - startTime).millisecondsLong

                animation.tick(elapsedTime)

                if (elapsedTime >= duration) {
                    if (animationsIterator.hasNext()) {
                        animation = animationsIterator.next()
                        startTime = DateTime.now()
                        animation.start()
                    } else {
//                        cancel()
                        setDelay(null)
                        println("Timer cancelled...")
                    }
                }
            }, delay)
        } else {
            if(!started) {
                val timer = object : Timer() {
                    override fun run() {
                        val duration = animation.duration
                        val elapsedTime = (DateTime.now() - startTime).millisecondsLong

                        animation.tick(elapsedTime)

                        if (elapsedTime >= duration) {
                            if (animationsIterator.hasNext()) {
                                animation = animationsIterator.next()
                                startTime = DateTime.now()
                                animation.start()
                            } else {
                                cancel()
                                println("Timer cancelled...")
                            }
                        }
                    }
                }
                timer.scheduleRepeating(1000 / 60)
                started = true
            }
        }
    }
}