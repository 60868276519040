package com.macrofocus.nastac.components

import com.macrofocus.nastac.theme.ThemeContext
import com.macrofocus.nastac.theme.Themes
import i18next.invoke
import i18next.useTranslation
import mui.icons.material.Brightness4TwoTone
import mui.icons.material.Brightness7TwoTone
import mui.material.IconButton
import mui.material.Tooltip
import react.FC
import react.PropsWithChildren
import react.ReactNode
import react.dom.aria.ariaLabel
import react.useContext

external interface ThemeButtonProps : PropsWithChildren {
}

val ThemeButton = FC<ThemeButtonProps> { props ->
    val (t, _) = useTranslation()

    var theme by useContext(ThemeContext)!!

    Tooltip {
        title = if(theme == Themes.Light) ReactNode(t("appBar.darkTheme")) else ReactNode(t("appBar.lightTheme"))

        val checked = theme == Themes.Dark

        IconButton {
            ariaLabel = "theme"

            onClick = { _ ->
                theme = if (checked) Themes.Light else Themes.Dark
            }

            if(checked) Brightness7TwoTone {} else Brightness4TwoTone {}
        }
    }
}