package com.macrofocus.eflux.animation

class ParallelAnimation : CompositeAnimation() {
    val animations = ArrayList<Animation>()

    override fun addAnimation(animation: Animation) {
        animations.add(animation)
    }

    override val duration: Long
        get() = animations.maxOf { it.duration }

    override fun start() {
        animations.forEach { it.start() }
    }

    override fun tick(time: Long) {
        animations.forEach { it.tick(time) }
    }
}