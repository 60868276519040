package org.molap.series

import kotlin.reflect.KClass

class SeriesFactory private constructor() {
//    private fun <V> build(vararg values: V?): MutableSeries<Int?, V> {
//        return if (values::class == Int::class) {
//            IntegerSeries(values as Array<Int?>) as MutableSeries<Int?, V>
//        } else {
//            DefaultSeries<V?>(values)
//        }
//    }

    private fun <V> buildInteger(values: IntArray, available: BooleanArray): Series<Int, Int?> {
        return IntegerSeries(values, available)
    }

    private fun <V> buildLong(values: LongArray, available: BooleanArray): Series<Int, Long?> {
        return LongSeries(values, available)
    }

    private fun <V> buildFloat(values: FloatArray, available: BooleanArray): Series<Int, Float?> {
        return FloatSeries(values, available)
    }
//
//    private fun <V> buildDouble(vararg values: Double): Series<Int, Double?> {
//        return DoubleSeries(values)
//    }

    private fun <V> buildDouble(values: DoubleArray): Series<Int, Double?> {
        return DoubleSeries(values)
    }

    private fun <V> buildDouble(values: DoubleArray, available: BooleanArray): MutableSeries<Int, Double?> {
        return DoubleSeries(values, available)
    }

    private fun <V> build(values: Array<V>, type: KClass<out Any> = Any::class): MutableSeries<Int, V> {
        return DefaultSeries(values, type = type)
    }

    private fun <K, V> build(name: Any, values: Array<V>, keys: Array<K>): Series<K, V> {
        return IndexedSeries(name, values, keys)
    }

//    private fun <V> build(name: Any, dictionary: Array<V>, pointers: ByteArray): Series<Int, V> {
//        return ByteDictionarySeries<V>(name, dictionary, pointers)
//    }
//
//    private fun <V> build(name: Any, dictionary: Array<V>, pointers: ShortArray): Series<Int, V> {
//        return ShortDictionarySeries<V>(name, dictionary, pointers)
//    }

    companion object {
        val instance = SeriesFactory()

//        fun <V> create(vararg values: V): MutableSeries<Int?, V> {
//            return instance.build(*values)
//        }

        fun createInteger(values: IntArray, available: BooleanArray): Series<Int, Int?> {
            return instance.buildInteger<Any>(values, available)
        }

        fun createLong(values: LongArray, available: BooleanArray): Series<Int, Long?> {
            return instance.buildLong<Any>(values, available)
        }

        fun createFloat(values: FloatArray, available: BooleanArray): Series<Int, Float?> {
            return instance.buildFloat<Any>(values, available)
        }

        fun createDouble(values: DoubleArray): Series<Int, Double?> {
            return instance.buildDouble<Any>(values)
        }

        fun createDouble(values: DoubleArray, available: BooleanArray): Series<Int, Double?> {
            return instance.buildDouble<Any>(values, available)
        }

        fun <V> create(values: Array<V>, type: KClass<out Any> = Any::class): Series<Int, out V> {
            return instance.build(values, type)
        }

        fun <K, V> create(name: Any, values: Array<V>, keys: Array<K>): Series<K, V> {
            return instance.build(name, values, keys)
        }

//        fun <V> create(name: Any, dictionary: Array<V>, pointers: ByteArray): Series<Int, V> {
//            return instance.build(name, dictionary, pointers)
//        }

//        fun <V> create(name: Any, dictionary: Array<V>, pointers: ShortArray): Series<Int, V> {
//            return instance.build(name, dictionary, pointers)
//        }
    }
}
