package ch.ethz.nastac.server

import kotlin.Boolean
import kotlin.String

internal object BuildKonfig {
  public val nastacVersion: String = "0.1.0"

  public val mosaic: Boolean = true
}
